import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../components/Buttons/Button";
import { Overlay } from "../../../../components/Overlay/Overlay";
import { dataRisk } from "../../../../data/dataRisk";
import { Form } from "../../../../modules/Forms/Form";
import { Status } from "../../../../modules/Forms/FormContext";
import { HiddenInput } from "../../../../modules/Forms/HiddenInput";
import { TextInput } from "../../../../modules/Forms/TextInput";
import { RequiredValidator } from "../../../../modules/Forms/validators/RequiredValidator";
import { useContractId } from "../../hooks/useContractId";
import { useDebouncedStatus } from "../../hooks/useDebouncedStatus";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const RiskAddDocumentOverlay: React.FC<Props> = ({
  isOpen,
  onClose,
}) => {
  return (
    <Overlay open={isOpen} onClose={onClose}>
      <Inner {...{ onClose }} />
    </Overlay>
  );
};

const Inner: React.FC<Pick<Props, "onClose">> = ({ onClose }) => {
  const contractId = useContractId();
  const [file, setFile] = useState<File | null>(null);
  const [description, setDescription] = useState("");
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const {
    mutate: doUpload,
    status: mutationStatus,
    isSuccess,
  } = useMutation({
    ...dataRisk.createDocument(contractId),
    onSuccess: async () => {
      await queryClient.invalidateQueries(
        dataRisk.fetchAllDocuments(contractId)
      );
      onClose();
    },
  });

  const debouncedStatus = useDebouncedStatus(mutationStatus);

  const status = isSuccess ? Status.SUCCESS : debouncedStatus;

  return (
    <div>
      <h4 className="mb-0 mt-0">{t("Add attachment")}</h4>

      <p className="passive">
        {t(
          "Add an attachment to the application, this will be archived with the rest of the application."
        )}
      </p>
      <Form
        onSubmit={(_, form) => {
          if (form.isInvalid) {
            return;
          }

          if (!file) {
            return;
          }

          doUpload({ comment: description, document: file });
        }}
      >
        <TextInput
          label={t("Attachment description")}
          value={description}
          onChange={setDescription}
          validators={[new RequiredValidator(t("Description is required"))]}
        />
        <div>
          <input
            type="file"
            onChange={(e) => setFile(e.target.files?.[0] || null)}
            ref={fileInputRef}
            style={{
              display: "none",
            }}
          />
          <div
            className="flex justify-start align-center gap-2"
            onClick={() => fileInputRef.current?.click()}
          >
            <Button onClick={() => {}} variant="outlined" size="small">
              {t("Select a file")}
            </Button>
            {file ? (
              <span>
                {file.name} <b>({formatFileSize(file.size)})</b>
              </span>
            ) : (
              t("No file selected")
            )}
          </div>
          <HiddenInput
            name="file"
            value={file ? true : undefined}
            validators={[new RequiredValidator("Please select a file")]}
            scrollToRef={fileInputRef}
          />
        </div>
        <Button
          type="submit"
          block
          variant="primary"
          status={status}
          className="mt-2"
        >
          {t("Upload attachment")}
        </Button>
      </Form>
    </div>
  );
};

const formatFileSize = function (bytes: number) {
  const sufixes = ["B", "KB", "MB", "GB", "TB"];
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  return `${(bytes / Math.pow(1024, i)).toFixed(2)} ${sufixes[i]}`;
};
