import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../components/Buttons/Button";
import { ConfirmButton } from "../../../../components/Buttons/ConfirmButton";
import { Overlay } from "../../../../components/Overlay/Overlay";
import { dataRisk } from "../../../../data/dataRisk";
import { DocumentType } from "../../../../data/models/ContractTypes";
import { RiskDocument } from "../../../../data/models/RiskTypes";
import { useContractId } from "../../hooks/useContractId";
import { useDebouncedStatus } from "../../hooks/useDebouncedStatus";
import { DocumentPreview } from "./DocumentPreview";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  document: RiskDocument;
  disabled: boolean;
}

const ALLOWED_TO_DELETE_TYPES: DocumentType[] = [
  DocumentType.RISK_DOCUMENT,
  DocumentType.VISA_SCREENING,
];

export const RiskDocumentOverlay: React.FC<Props> = ({
  isOpen,
  onClose,
  document,
  disabled,
}) => {
  return (
    <Overlay open={isOpen} onClose={onClose} local={false}>
      <Inner {...{ onClose, document, disabled }} />
    </Overlay>
  );
};

const Inner: React.FC<Pick<Props, "onClose" | "document" | "disabled">> = ({
  onClose,
  document,
  disabled,
}) => {
  const { t } = useTranslation();
  const contractId = useContractId();

  const { documentDescription } = document;

  const { mutate: deleteDocument, status: deleteMutationStatus } = useMutation(
    dataRisk.deleteDocument(contractId, document.documentId)
  );

  const deleteStatus = useDebouncedStatus(deleteMutationStatus, {
    disabled,
  });

  return (
    <div>
      <div className="mb-2 mt-0 flex align-center justify-between">
        <h3 className="mb-0 mt-0">{t("Document")}</h3>
        {ALLOWED_TO_DELETE_TYPES.includes(document.documentType) && (
          <ConfirmButton
            onClick={() => deleteDocument()}
            confirmButtonText={t("Yes, delete")}
            regretButtonText={t("Cancel")}
            variant="outlined"
            status={deleteStatus}
            color="error"
            size="small"
          >
            {t("Delete document")}
          </ConfirmButton>
        )}
      </div>

      <div>
        <span>{documentDescription}</span>
      </div>

      {!!document?.created && (
        <div className="mt-2">
          <h4>{t("Preview")}</h4>
          <div className="flex-column">
            <DocumentPreview document={document} height="700" />
          </div>
        </div>
      )}
      <Button onClick={onClose} block variant="outlined" className="mt-1">
        {t("Close")}
      </Button>
    </div>
  );
};
