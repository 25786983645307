import React, { useMemo, useRef } from "react";
import cx from "classnames";
import styles from "./SignatoryNationality.module.scss";
import { useTranslation } from "react-i18next";
import { Country, SelectedSignatory } from "../../data/models/ContractTypes";
import { useContract } from "../../hooks/useContract";
import { isSweden } from "../../data/models/ContractUtils";
import { Dynamic } from "../../components/Animations/Dynamic";
import { TextInput } from "../Forms/TextInput";
import { RequiredValidator } from "../Forms/validators/RequiredValidator";
import { MinLengthValidator } from "../Forms/validators/MinLengthValidator";
import { MaxLengthValidator } from "../Forms/validators/MaxLengthValidator";
import { Validations } from "../../stories/Onboarding/Owners/Owner";
import { FlagRounded } from "../../components/Countries/FlagRounded";
import { HiddenInput } from "../Forms/HiddenInput";

interface Props {
  associate: SelectedSignatory;
  onChange: (value: string, name: string) => void;
}

export const applicableNationalities: Country[] = [
  Country.SWEDEN,
  Country.DENMARK,
  Country.NORWAY,
  Country.FINLAND,
];

export const SignatoryNationality: React.FunctionComponent<Props> = (props) => {
  const { t } = useTranslation();
  const { associate, onChange } = props;
  const contract = useContract();
  const prePolulated = useRef<boolean>(
    associate.nationality === Country.SWEDEN &&
      isSweden(contract) &&
      !!associate.nationalId
    // We get SSN only in Sweden
  );

  const ssnValidationsByCountry = useMemo(
    () => Validations[associate.nationality || contract.country],
    [contract.country, associate.nationality]
  );

  if (prePolulated.current) {
    return <SwedishNationality {...props} />;
  }

  return (
    <>
      <div className={styles.label}>{t("Nationality")}</div>

      <HiddenInput
        value={associate.nationality ? [true] : undefined}
        validators={[
          new RequiredValidator(
            t("You must add a nationality for this person")
          ),
        ]}
      />

      <div className={cx(styles.nationality)}>
        {applicableNationalities.map((nationality) => {
          return (
            <button
              key={nationality}
              onClick={(ev) => {
                ev.preventDefault();
                onChange(nationality, "nationality");
              }}
              className={cx(styles.button, {
                [styles.active]: nationality === associate.nationality,
              })}
            >
              <FlagRounded country={nationality} height="50px" />
            </button>
          );
        })}
      </div>

      <Dynamic name={associate.nationality || "empty"}>
        {associate.nationality ? (
          <TextInput
            label={t("Personal identity number")}
            name="nationalId"
            onChange={onChange}
            value={associate.nationalId}
            hint={ssnValidationsByCountry.hint}
            placeholder={`${t("E.g.")} "${
              ssnValidationsByCountry.placeholder
            }"`}
            validators={[
              new RequiredValidator(t("Personal identity number is required")),
              new MinLengthValidator(
                ssnValidationsByCountry.hint.length,
                t("At least {{length}} characters are required ({{hint}})", {
                  length: ssnValidationsByCountry.hint.length,
                  hint: ssnValidationsByCountry.hint,
                })
              ),
              new MaxLengthValidator(
                ssnValidationsByCountry.hint.length,
                t(
                  "Not more than {{length}} characters are allowed ({{hint}})",
                  {
                    length: ssnValidationsByCountry.hint.length,
                    hint: ssnValidationsByCountry.hint,
                  }
                )
              ),
            ]}
          />
        ) : null}
      </Dynamic>
    </>
  );
};

const SwedishNationality: React.FunctionComponent<Props> = ({ associate }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.label}>{t("Personal identity number")}</div>
      <div className={styles.swedish}>
        <FlagRounded country={associate.nationality as Country} height="20px" />
        <i>{associate.nationalId}</i>
      </div>
    </>
  );
};
