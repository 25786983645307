import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styles from "./PhoneNumberCountry.module.scss";
import { getCountryDisplayName } from "../../components/langUtils";
import { Language } from "../../data/models/ContractTypes";
import {
  getCountryFromPhoneNumber,
  PhoneValidatorResponseCountry,
  PhoneValidatorResponseName,
} from "./PhoneToCountry";
import { Flag } from "../../components/Countries/Flag";

interface Props {
  phoneNumber?: string;
}

const MIN_NUMBER_OF_CHARS = 3;

export const PhoneNumberCountry = ({ phoneNumber }: Props) => {
  const { i18n } = useTranslation();

  const country = useMemo(() => {
    if (!phoneNumber) {
      return;
    }

    if (phoneNumber.length < MIN_NUMBER_OF_CHARS) {
      return;
    }

    const searchItem = getCountryFromPhoneNumber(phoneNumber);

    if (!searchItem.valid) {
      // return getCountryNameFromPhoneNumber(
      //   phoneNumber,
      //   Math.max(phoneNumber.length, 5)
      // );

      return null;
    }

    const phoneCountry =
      (searchItem as PhoneValidatorResponseCountry).country ||
      (searchItem as PhoneValidatorResponseName).name;

    return (
      <div className={styles.flag}>
        <Flag country={phoneCountry} height={20} />{" "}
        {getCountryDisplayName(phoneCountry, i18n.language as Language)}
      </div>
    );
  }, [phoneNumber, i18n]);

  return <>{country}</>;
};
