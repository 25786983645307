import { useContext, useEffect } from "react";
import { queryClient } from "..";
import { dataContract } from "../data/dataContract";
import { useSuspenseQueries } from "@tanstack/react-query";
import { LegalEntityType } from "../data/models/ContractTypes";
import { OnboardingPath } from "../stories/Onboarding/routes";
import { Path } from "../components/storyTypes";
import { NavigationStoryContext } from "../components/Story/Navigation/NavigationStory";

const SOLE_PROPRIETARY_EXLUDED_ROUTES: Path[] = [
  OnboardingPath.SIGNATORIES,
  OnboardingPath.SIGNING_GROUPS,
  OnboardingPath.OWNERS,
];

export function useContract() {
  const [{ data: contract }] = useSuspenseQueries({
    queries: [dataContract.fetchContract()],
  });

  const { setFilteredRoutes, routes } = useContext(NavigationStoryContext);

  useEffect(() => {
    const { legalEntityType } = contract.companyDetails;
    if (legalEntityType === LegalEntityType.SOLE_PROPRIETARY) {
      setFilteredRoutes(
        routes.filter(
          (route) => !SOLE_PROPRIETARY_EXLUDED_ROUTES.includes(route.path)
        )
      );
    } else {
      setFilteredRoutes(routes);
    }
  }, [contract, setFilteredRoutes, routes]);

  return contract;
}

export function useInvalidateContract() {
  const invalidate = () => {
    queryClient.invalidateQueries({ queryKey: dataContract.getContractKey() });
  };

  return invalidate;
}
