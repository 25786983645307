import React, {
  PropsWithChildren,
  ReactElement,
  ReactNode,
  useCallback,
  useId,
  useRef,
} from "react";
import cx from "classnames";
import styles from "./SelectableList.module.scss";
import { useValidation } from "./hooks/useValidation";
import { useForm } from "./hooks/useForm";
import { BaseValidator } from "./validators/BaseValidator";
import { Stringifiable } from "../../components/types";
import { Radio } from "./Radio";
import { HiddenInput } from "./HiddenInput";
import { RequiredValidatorName } from "./validators/RequiredValidator";
import { Form } from "./Form";

interface Props<T extends Stringifiable> {
  className?: string;
  value?: T;
  onChange: (
    value: T,
    name: string,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  validators?: BaseValidator[];
  children: ReactNode;
  verticalAlignment?: "start" | "center";
  disabled?: boolean;
}

interface ItemProps<T extends Stringifiable> {
  selectedValue?: T;
  disabled?: boolean;
  value: T;
  children: ReactNode;
  onChange?: (
    value: T,
    name: string,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
}

export function SelectableList<T extends Stringifiable>({
  className,
  onChange,
  value,
  validators = [],
  children,
  verticalAlignment = "start",
  disabled,
}: Props<T>) {
  const ref = useRef<HTMLUListElement>(null);
  const inputId = useId();
  const [validity] = useValidation(value, validators);
  useForm(inputId, validity, value, () => {}, ref);

  if (validators.length > 1) {
    throw Error("SelectableList: Multiple validators are not applicable'");
  }

  if (validators.length === 1 && validators[0].name !== RequiredValidatorName) {
    throw Error("SelectableList: Only 'Required validator' is applicable");
  }

  return (
    <Form>
      <HiddenInput value={value} validators={validators} />

      <ul
        className={cx(
          styles.wrapper,
          className,
          styles[verticalAlignment],
          styles[validity]
        )}
        ref={ref}
      >
        {React.Children.map(children, (child: ReactNode) => {
          const item = child as ReactElement<PropsWithChildren>;
          const { type } = item;

          if (type !== SelectableListItem) {
            throw Error(
              "SelectableList: Child must be of type 'SelectableListItem'"
            );
          }

          return React.cloneElement(item as React.ReactElement<ItemProps<T>>, {
            selectedValue: value,
            onChange,
            disabled,
          });
        })}
      </ul>
    </Form>
  );
}

export function SelectableListItem<T extends Stringifiable>({
  onChange,
  value,
  children,
  selectedValue,
  disabled,
}: ItemProps<T>) {
  const identifier = useId();

  const internalChange = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (!onChange) {
        return;
      }

      onChange(value as any, identifier, event);
    },
    [onChange, value, identifier]
  );

  return (
    <li
      className={cx(styles.listItem, "mb-2", {
        [styles.active]: value === selectedValue,
        [styles.disabled]: disabled,
      })}
    >
      <button className={cx(styles.button)} onClick={internalChange}>
        <div className={cx(styles.radio)}>
          <Radio checked={value === selectedValue} className={styles.radio} />
        </div>
        <div className={styles.body}>{children}</div>
      </button>
    </li>
  );
}

SelectableList.SelectableListItem = SelectableListItem;
