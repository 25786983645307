import { VALIDATION_STATE } from "./../FormContext";
import { ValidationResponse } from "../hooks/useValidation";
import { BaseValidator } from "./BaseValidator";

export const MIN_PHONE_LENGTH = 7;
export class PhoneCountryCodeValidator extends BaseValidator {
  name = "PhoneCountryCodeValidator";

  validate(value: string): Promise<ValidationResponse> {
    return new Promise<ValidationResponse>((resolve) => {
      if (value.length < 4 || value[0] === "+") {
        resolve({ status: VALIDATION_STATE.SUCCESS });
      } else {
        resolve({
          status: VALIDATION_STATE.FAILED,
          message: this.error,
        });
      }
    });
  }
}
