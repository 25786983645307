import React from "react";
import { Bankgiro } from "./Bankgiro/Bankgiro";
import { isSweden } from "../../../data/models/ContractUtils";
import { useSuspenseQueries } from "@tanstack/react-query";
import { dataContract } from "../../../data/dataContract";
import { Iban } from "./Iban/Iban";

export const Bank: React.FunctionComponent = () => {
  const [{ data: contract }] = useSuspenseQueries({
    queries: [dataContract.fetchContract()],
  });

  if (isSweden(contract)) {
    return <Bankgiro />;
  }

  return <Iban />;
};
