import React, { useCallback } from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { useContract } from "../../../hooks/useContract";
import { Wrapper } from "../../../components/Wrapper";
import { Contract, ContractProgress } from "../../../data/models/ContractTypes";
import { useStoryNavigate } from "../../../hooks/useStoryNavigate";
import { useMutation } from "@tanstack/react-query";
import { CommitRequest, dataContract } from "../../../data/dataContract";
import { OnboardingPath } from "../routes";
import { Dynamic } from "../../../components/Animations/Dynamic";
import { GenericError } from "../../../components/Errors/GenericError";
import { BASE_ROUTE as PRIMARY_BASE_ROUTE } from "../Onboarding";
import { generatePath } from "react-router-dom";
import { SIGNING_BASE_ROUTE } from "../../Signing/Signing";
import { SigningPath } from "../../Signing/routes";
import { cleanPath } from "../../../components/utils";
import { StoryButtons } from "../../StoryButtons";
import { isSoleProprietary } from "../../../data/models/ContractUtils";
import { View } from "../../../modules/View/View";
import { SummarySection } from "./SummarySection";
import { CompanyDataConfirm } from "./CompanyDataConfirm/CompanyDataConfirm";
import { ContactDetails } from "./ContactDetails/ContactDetails";
import { LocationSetup } from "./LocationSetup/LocationSetup";

function getSigningUrls(): CommitRequest {
  const internal = cleanPath(`${PRIMARY_BASE_ROUTE}${OnboardingPath.SIGNING}`);
  const external = cleanPath(`${SIGNING_BASE_ROUTE}${SigningPath.SIGNING}`);

  return {
    internalRedirect: `${window.location.origin}${internal}`,
    externalSigning: `${window.location.origin}${generatePath(external, {
      linkId: "{id}",
    })}`,
  };
}

function shouldSign(contract: Contract): boolean {
  return contract.selectedSignatories.some((signee) => signee.primary);
}

export const Summary: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const contract = useContract();
  const { navigate } = useStoryNavigate();

  const {
    mutate: commitContractDetails,
    isPending,
    isError,
    reset,
  } = useMutation({
    mutationFn: () => dataContract.commitContractDetails(getSigningUrls()),
    onSuccess: () => {
      if (shouldSign(contract)) {
        navigate(OnboardingPath.SIGNING);
        return;
      }

      // Todo, update routes?
      navigate(OnboardingPath.COMPLETE);
    },
  });

  const onConfirm = useCallback(() => {
    reset();
    commitContractDetails();
  }, [reset, commitContractDetails]);

  return (
    <Wrapper>
      <View header={t("Review")} indicator={false}>
        <SummarySection
          phase={ContractProgress.COMPANY_DATA_CONFIRM}
          number={1}
        >
          <CompanyDataConfirm contract={contract} />
        </SummarySection>
        <SummarySection phase={ContractProgress.CONTACT_DETAILS} number={2}>
          <ContactDetails contract={contract} />
        </SummarySection>
        <SummarySection phase={ContractProgress.LOCATION_SETUP} number={3}>
          <LocationSetup contract={contract} />
        </SummarySection>
        <SummarySection phase={ContractProgress.FINANCIAL_KYC} number={4}>
          <div style={{ minHeight: "60px", backgroundColor: "#f7f7f7" }} />
        </SummarySection>
        <SummarySection phase={ContractProgress.BUSINESS_RISK} number={5}>
          <div style={{ minHeight: "60px", backgroundColor: "#f7f7f7" }} />
        </SummarySection>
        <SummarySection phase={ContractProgress.PAYOUT_ACCOUNT} number={6}>
          <div style={{ minHeight: "60px", backgroundColor: "#f7f7f7" }} />
        </SummarySection>

        {!isSoleProprietary(contract) ? (
          <>
            <SummarySection
              phase={ContractProgress.BENEFICIAL_OWNER}
              number={7}
            >
              <div style={{ minHeight: "60px", backgroundColor: "#f7f7f7" }} />
            </SummarySection>
            <SummarySection phase={ContractProgress.SIGNATORIES} number={8}>
              <div style={{ minHeight: "60px", backgroundColor: "#f7f7f7" }} />
            </SummarySection>
          </>
        ) : null}

        <Dynamic name={isError.toString()}>
          {isError ? <GenericError /> : null}
        </Dynamic>

        <div className={cx("mt-4")}>
          <StoryButtons
            action={onConfirm}
            disabled={isPending}
            nextButton={<>{t("Confirm")} 👍</>}
          />
        </div>
      </View>
    </Wrapper>
  );
};
