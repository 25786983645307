import React, { useContext, useEffect } from "react";
import styles from "./Onboarding.module.scss";
import { NavigationStory } from "../../components/Story/Navigation/NavigationStory";
import { SalesLocations } from "./SalesLocations/SalesLocations";
import { Financial } from "./Financial/Financial";
import { Owners } from "./Owners/Owners";
import { Contact } from "./Contact/Contact";
import { Signing } from "./Signing/Signing";
import { Complete } from "./Complete/Complete";
import { useSearchParams } from "react-router-dom";
import { Country, TerminalType } from "../../data/models/ContractTypes";
import { GlobalContext } from "../..";
import { AuthenticatedSuspense } from "../../pages/AuthenticatedSuspense";
import { QueryKey, SearchParams, Store, STORE_KEY } from "../../data/Store";
import { filterDefined } from "../../components/utils";
import { Checkout } from "./Checkout/Checkout";
import { SignIn } from "./SignIn/SignIn";
import { Information } from "./Information/Information";
import { OnboardingPath } from "./routes";
import { ProductSelection } from "./ProductSelection/ProductSelection";
import { Bank } from "./BankAccount/Bank";
import { SigningGroups } from "./SigningGroups/SigningGroups";
import { Signatories } from "./Signatories/Signatories";
import { Summary } from "./Summary/Summary";
import { StoryFrameProps } from "../../components/storyTypes";
import { Mcc } from "./Mcc/Mcc";
import { useTranslation } from "react-i18next";
import { FinancialEstimates } from "./Financial/FinancialEstimates";
import { BusinessRisk } from "./BusinessRisk/BusinessRisk";
import { Products } from "./Products/Products";
import { DocumentUpload } from "./DocumentUpload/DocumentUpload";

export const BASE_ROUTE = "/";
export const STORY_EXAMPLE_ROUTE = `${BASE_ROUTE}*`;
const TRACKING_PREFIX = "utm_";

export const OnboardingName: Record<OnboardingPath, string> = {
  [OnboardingPath.PRODUCT_SELECTION]: "Product selection",
  [OnboardingPath.CHECKOUT]: "Checkout",
  [OnboardingPath.SIGN_IN]: "Sign In",
  [OnboardingPath.CONTACT]: "Contact",
  [OnboardingPath.INFORMATION]: "Information",
  [OnboardingPath.SALES_LOCATIONS]: "Sales locations",
  [OnboardingPath.MCC]: "Industry",
  [OnboardingPath.DOCUMENT_UPLOAD]: "Documents",
  [OnboardingPath.FINANCIAL]: "Financial information",
  [OnboardingPath.FINANCIAL_ESTIMATES]: "Financial estimates",
  [OnboardingPath.BUSINESS_RISK]: "Financial risk",
  [OnboardingPath.PRODUCTS]: "Bundle",
  [OnboardingPath.BANK_ACCOUNT]: "Bank",
  [OnboardingPath.OWNERS]: "Owners",
  [OnboardingPath.SIGNING_GROUPS]: "Signing Groups",
  [OnboardingPath.SIGNATORIES]: "Signatories",
  [OnboardingPath.SUMMARY]: "Summary",
  [OnboardingPath.SIGNING]: "Signing",
  [OnboardingPath.COMPLETE]: "Done",
};

export const routes: StoryFrameProps[] = [
  {
    path: OnboardingPath.PRODUCT_SELECTION,
    element: (
      <AuthenticatedSuspense>
        <ProductSelection />
      </AuthenticatedSuspense>
    ),
  },
  {
    path: OnboardingPath.CHECKOUT,
    element: (
      <AuthenticatedSuspense>
        <Checkout />
      </AuthenticatedSuspense>
    ),
  },
  {
    path: OnboardingPath.SIGN_IN,
    element: (
      <AuthenticatedSuspense>
        <SignIn />
      </AuthenticatedSuspense>
    ),
  },
  {
    path: OnboardingPath.INFORMATION,
    element: (
      <AuthenticatedSuspense>
        <Information />
      </AuthenticatedSuspense>
    ),
  },
  {
    path: OnboardingPath.CONTACT,
    element: (
      <AuthenticatedSuspense>
        <Contact />
      </AuthenticatedSuspense>
    ),
  },
  {
    path: OnboardingPath.SALES_LOCATIONS,
    element: (
      <AuthenticatedSuspense>
        <SalesLocations />
      </AuthenticatedSuspense>
    ),
  },
  {
    path: OnboardingPath.MCC,
    element: (
      <AuthenticatedSuspense>
        <Mcc />
      </AuthenticatedSuspense>
    ),
  },
  {
    path: OnboardingPath.DOCUMENT_UPLOAD,
    element: (
      <AuthenticatedSuspense>
        <DocumentUpload />
      </AuthenticatedSuspense>
    ),
  },
  {
    path: OnboardingPath.PRODUCTS,
    element: (
      <AuthenticatedSuspense>
        <Products />
      </AuthenticatedSuspense>
    ),
  },
  {
    path: OnboardingPath.FINANCIAL,
    element: (
      <AuthenticatedSuspense>
        <Financial />
      </AuthenticatedSuspense>
    ),
  },
  {
    path: OnboardingPath.FINANCIAL_ESTIMATES,
    element: (
      <AuthenticatedSuspense>
        <FinancialEstimates />
      </AuthenticatedSuspense>
    ),
  },
  {
    path: OnboardingPath.BUSINESS_RISK,
    element: (
      <AuthenticatedSuspense>
        <BusinessRisk />
      </AuthenticatedSuspense>
    ),
  },
  {
    path: OnboardingPath.BANK_ACCOUNT,
    element: (
      <AuthenticatedSuspense>
        <Bank />
      </AuthenticatedSuspense>
    ),
  },
  {
    path: OnboardingPath.OWNERS,
    element: (
      <AuthenticatedSuspense>
        <Owners />
      </AuthenticatedSuspense>
    ),
  },
  {
    path: OnboardingPath.SIGNING_GROUPS,
    element: (
      <AuthenticatedSuspense>
        <SigningGroups />
      </AuthenticatedSuspense>
    ),
  },
  {
    path: OnboardingPath.SIGNATORIES,
    element: (
      <AuthenticatedSuspense>
        <Signatories />
      </AuthenticatedSuspense>
    ),
  },
  {
    path: OnboardingPath.SUMMARY,
    element: (
      <AuthenticatedSuspense>
        <Summary />
      </AuthenticatedSuspense>
    ),
  },
  {
    path: OnboardingPath.SIGNING,
    hideProgress: true,
    element: (
      <AuthenticatedSuspense>
        <Signing />
      </AuthenticatedSuspense>
    ),
  },
  {
    path: OnboardingPath.COMPLETE,
    hideProgress: true,
    element: (
      <AuthenticatedSuspense>
        <Complete />
      </AuthenticatedSuspense>
    ),
  },
];

function getUtmParamsAsString(params: URLSearchParams): string | undefined {
  const tracking: string[] = [];
  params.forEach((value, key) => {
    if (key.startsWith(TRACKING_PREFIX)) {
      tracking.push(`${key}=${value}`);
    }
  });

  if (!tracking.length) {
    return;
  }

  return tracking.join("&");
}

function getParams(params: URLSearchParams): SearchParams {
  return {
    [TerminalType.DX8000]: Number(
      params.get(TerminalType.DX8000) ||
        params.get(TerminalType.DX8000.toLocaleLowerCase())
    ) as number,
    [TerminalType.MOVE5000]: Number(
      params.get(TerminalType.MOVE5000) ||
        params.get(TerminalType.MOVE5000.toLocaleLowerCase())
    ) as number,
    [QueryKey.VOUCHER]: params.get(QueryKey.VOUCHER) || undefined,
    [QueryKey.CAMPAIGN_ID]: params.get(QueryKey.PROMOTION_CODE) || undefined,
    [QueryKey.COUNTRY]: params.get(QueryKey.COUNTRY)?.toUpperCase() as Country,
    [QueryKey.TRACKING_ID]: getUtmParamsAsString(params),
    [QueryKey.SESSION]: params.get(QueryKey.SESSION) || undefined,
    [QueryKey.LANGUAGE]: params.get(QueryKey.LANGUAGE) || undefined,
  };
}

export const Onboarding: React.FunctionComponent = () => {
  const [params] = useSearchParams();
  const { i18n } = useTranslation();
  const { search: global, setSearch: setGlobal } = useContext(GlobalContext);
  const searchParams: SearchParams = filterDefined(getParams(params)) || {};
  console.log("searchParams", searchParams);

  useEffect(() => {
    const merged: SearchParams = { ...global, ...searchParams };
    const savedValues = Store.getValue(STORE_KEY.STORE_SAVED_QUERY);
    console.log("savedValues", savedValues);

    if (!merged[QueryKey.COUNTRY]) {
      merged[QueryKey.COUNTRY] = savedValues[QueryKey.COUNTRY];
    }

    if (!merged[QueryKey.CAMPAIGN_ID]) {
      merged[QueryKey.CAMPAIGN_ID] = savedValues[QueryKey.CAMPAIGN_ID];
    }

    if (!merged[TerminalType.DX8000]) {
      merged[TerminalType.DX8000] = savedValues[TerminalType.DX8000];
    }

    if (!merged[TerminalType.MOVE5000]) {
      merged[TerminalType.MOVE5000] = savedValues[TerminalType.MOVE5000];
    }

    if (!merged[QueryKey.CAMPAIGN_ID]) {
      merged[QueryKey.CAMPAIGN_ID] = savedValues[QueryKey.CAMPAIGN_ID];
    }

    if (searchParams.language) {
      i18n.changeLanguage(searchParams.language);
    }

    setGlobal(merged);
    Store.setValue(STORE_KEY.STORE_SAVED_QUERY, merged);

    // Set on first render only
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.onboarding}>
      <NavigationStory routes={routes} baseRoute={BASE_ROUTE} />
    </div>
  );
};
