import React, { useState, useMemo, useRef } from "react";
import cx from "classnames";
import styles from "./Mcc.module.scss";
import { useTranslation } from "react-i18next";
import { Wrapper } from "../../../components/Wrapper";
import { Form } from "../../../modules/Forms/Form";
import { View } from "../../../modules/View/View";
import { useMutation, useSuspenseQueries } from "@tanstack/react-query";
import { dataSalesLocation } from "../../../data/dataSalesLocation";
import { Option } from "../../../components/types";
import {
  Contract,
  Language,
  MCC as MccInterface,
  SalesLocation,
} from "../../../data/models/ContractTypes";
import { TFunction } from "i18next";
import { dataContract } from "../../../data/dataContract";
import { HiddenInput } from "../../../modules/Forms/HiddenInput";
import { ErrorBoundary } from "@sentry/react";
import { RequiredValidator } from "../../../modules/Forms/validators/RequiredValidator";
import { Select } from "../../../modules/Forms/Select";
import { Button } from "../../../components/Buttons/Button";
import { StoryButtons } from "../../StoryButtons";
import { queryClient } from "../../..";
import { OnboardingPath } from "../routes";
import { useStoryNavigate } from "../../../hooks/useStoryNavigate";
import { Dynamic } from "../../../components/Animations/Dynamic";
import { MccCategory } from "../../../data/models/mcc";
import { MccCategoryLabel } from "../../../components/MccCategoryLabel/MccCategoryLabel";

const mostCommonCategories: MccCategory[] = [
  MccCategory.HOTELS_AND_ACCOMODATION,
  MccCategory.FOOD_DRINKS_AND_RESTAURANTS,
  MccCategory.HEALTH_MEDICAL_AND_BEAUTY,
  MccCategory.CLOTHING_STORES,
  MccCategory.MISCELLANEOUS_STORES,
];

function getLabelFromMCC(mcc: MccInterface): string {
  return mcc.label;
}

function getCategoryOptions(mccs: MccInterface[]) {
  const uniqueCategories = mccs
    .reduce((acc: MccCategory[], mcc) => {
      if (!acc.includes(mcc.salesCategory)) {
        acc.push(mcc.salesCategory);
      }

      return acc;
    }, [])
    .filter((category) => !mostCommonCategories.includes(category))
    .sort();

  return uniqueCategories;
}

function getFilteredMccOptionsByCategory(
  mccs: MccInterface[],
  t: TFunction,
  category?: string
): Option<string>[] {
  let opts;

  if (category) {
    opts = mccs
      .filter((opt) => opt.salesCategory === category)
      .map((opt) => ({
        value: opt.code,
        text: getLabelFromMCC(opt),
      })) as Option<string>[];
  } else {
    opts = mccs.map((opt) => ({
      value: opt.code,
      text: getLabelFromMCC(opt),
    })) as Option<string>[];
  }

  return [
    {
      value: "",
      text: t("Select a merchant category code"),
    } as unknown as Option<string>,
  ].concat(opts);
}

export const Mcc: React.FunctionComponent = () => {
  const { t, i18n } = useTranslation();
  const { navigate } = useStoryNavigate();
  const queryKey = dataContract.getContractKey();
  const mccSelectRef = useRef<HTMLDivElement>(null);

  const [{ data: contract }, { data: mccs }] = useSuspenseQueries({
    queries: [
      dataContract.fetchContract(),
      dataSalesLocation.fetchMCCs(i18n.language as Language),
    ],
  });

  const [mcc, setMcc] = useState<string | undefined>(contract.locations[0].mcc);
  const [category, setCategory] = useState<MccCategory>();

  const [showAllCategories, setShowAllCategories] = useState<boolean>(() => {
    const currentCategory = mccs.find(
      ({ code }) => code === (mcc || contract.locations[0].mcc)
    )?.salesCategory;
    if (!currentCategory) {
      return false;
    }

    return !mostCommonCategories.includes(currentCategory);
  });

  const otherCategories = useMemo(() => {
    if (!mccs) {
      return [];
    }

    return getCategoryOptions(mccs);
  }, [mccs]);

  const visibleCategories = showAllCategories
    ? [...mostCommonCategories, ...otherCategories]
    : mostCommonCategories;

  // For now, only a single store is allowed
  const selectedMcc = mcc;

  const selectedMccExtended = mccs?.find(
    ({ code }) => code === (mcc || contract.locations[0].mcc)
  );

  const selectedCategory = category || selectedMccExtended?.salesCategory;

  const mccOptions = useMemo(() => {
    return getFilteredMccOptionsByCategory(mccs || [], t, selectedCategory);
  }, [mccs, t, selectedCategory]);

  // const {
  //   // TODO
  //   mutate: commit, // eslint-disable-line
  //   isError: isCommitError, // eslint-disable-line
  // } = useMutation({
  //   mutationFn: () => dataSalesLocation.commitLocations(),
  //   onSuccess: () => {
  //     invalidate();
  //     navigate(OnboardingPath.FINANCIAL);
  //   },
  // });

  const {
    // TODO
    isError, // eslint-disable-line
    isPending,
    mutate: onSave,
  } = useMutation({
    mutationFn: async (updatedLocation: SalesLocation) =>
      dataSalesLocation.saveLocation(updatedLocation),
    onMutate: async (updatedLocation: SalesLocation) => {
      await queryClient.cancelQueries({
        queryKey,
      });

      const previousContract = queryClient.getQueryData<Contract>(queryKey);
      if (!previousContract) {
        return;
      }

      const locations = previousContract.locations.map((location) => {
        if (updatedLocation.id === location.id) {
          return updatedLocation;
        }

        return location;
      });

      const update: Contract = {
        ...previousContract,
        locations,
      };

      queryClient.setQueryData<Contract>(queryKey, update);

      return { previousContract };
    },
    onError: (_, __, context) => {
      if (!context) {
        return;
      }

      queryClient.setQueryData(queryKey, context.previousContract);
    },
  });

  return (
    <Wrapper>
      <Form
        onSubmit={async (_, form) => {
          if (form.isInvalid) {
            return;
          }

          const currentContract = await queryClient.fetchQuery(
            dataContract.fetchContract()
          );

          if (currentContract.documents.length > 0) {
            navigate(OnboardingPath.DOCUMENT_UPLOAD);
            return;
          }

          navigate(OnboardingPath.PRODUCTS);
        }}
      >
        <View header={t("Select your Merchant Category Code")} size="small">
          <p>
            {t(
              `Selecting an accurate Merchant Category Code for your business is important, if the "Merchant Category Code" isn't accurate it might lead to an extended review process of your application and we might need to reach out to you for additional information.`
            )}
          </p>
          <ErrorBoundary>
            <b>{t("Select an industry")}</b>
            <div className={cx("mt-1", styles.shortcuts)}>
              {visibleCategories.map((item) => {
                return (
                  <div key={item} className="mb-1">
                    <Button
                      block
                      variant={
                        item === selectedCategory ? "selected" : "outlined"
                      }
                      onClick={() => {
                        const update = {
                          ...contract.locations[0],
                          mcc: undefined,
                        };
                        onSave(update);
                        setMcc(undefined);
                        setCategory(item);
                        setTimeout(() => {
                          mccSelectRef.current?.scrollIntoView({
                            behavior: "smooth",
                            block: "start",
                          });
                        }, 100);
                      }}
                    >
                      <MccCategoryLabel value={item} />
                    </Button>
                  </div>
                );
              })}
              {!showAllCategories && (
                <Button
                  block
                  variant="text"
                  onClick={() => setShowAllCategories(!showAllCategories)}
                >
                  {t("Show all industries")}
                </Button>
              )}
              <HiddenInput
                value={selectedCategory}
                validators={[
                  new RequiredValidator(t("Please select an industry")),
                ]}
              />
            </div>

            <div className="full-width">
              <hr />
            </div>

            <div ref={mccSelectRef}>
              <Dynamic name={selectedCategory || "none"}>
                {selectedCategory ? (
                  <div className={cx(styles.box)}>
                    <Select
                      label={t("Select a merchant category code")}
                      name="mcc"
                      className={cx(styles.mcc, styles.select, "pt-1")}
                      value={selectedMcc}
                      options={mccOptions}
                      onChange={(newCode) => {
                        const opt = mccs.find(({ code }) => code === newCode);
                        if (opt) {
                          setMcc(opt.code);
                          setCategory(opt.salesCategory);
                          const update = {
                            ...contract.locations[0],
                            mcc: opt.code,
                          };
                          onSave(update);
                        }
                      }}
                      validators={[
                        new RequiredValidator(
                          t("Please select a merchant category code")
                        ),
                      ]}
                    />
                  </div>
                ) : null}
              </Dynamic>
            </div>
          </ErrorBoundary>

          <div className="mt-4">
            <StoryButtons disabled={isPending} />
          </div>
        </View>
      </Form>
    </Wrapper>
  );
};
