import { API } from "./API";
import {
  Language,
  LocationId,
  MCC,
  SalesLocation,
} from "./models/ContractTypes";
import { MCC_LIST } from "./models/mcc";
import { BASE_PATH } from "./proxy";

const supportedMccLanguages: Language[] = [
  Language.ENGLISH,
  Language.SWEDISH,
  Language.DANISH,
];

export const dataSalesLocation = {
  getMCCs: async (language: Language): Promise<MCC[]> => {
    const actualLanguage = supportedMccLanguages.includes(language)
      ? language
      : Language.ENGLISH;
    const { origin } = new URL(window.location.href);
    const mccs = await API.get<Record<string, string>>(
      `${origin}/mcc/mcc-${actualLanguage}.json`
    );

    return MCC_LIST.map((mcc) => ({
      ...mcc,
      label: mccs[mcc.code] || "",
    }));
  },

  getMCCsKey: (lang: Language): string[] => {
    return ["mcc", lang];
  },

  fetchMCCs: (lang: Language) => ({
    queryKey: dataSalesLocation.getMCCsKey(lang),
    queryFn: () => dataSalesLocation.getMCCs(lang),
  }),

  loadMccCodes: () => {},

  createLocation: () => {
    return API.post(`${BASE_PATH}/location/new`);
  },

  saveLocation: (location: SalesLocation) => {
    return API.post(`${BASE_PATH}/location`, location);
  },

  searchCity: (postalCode: string) =>
    API.get(`${BASE_PATH}/location/address?postalCode=${postalCode}`),

  /**
   * Can't delete the last location
   */
  deleteLocation: (id: LocationId) => API.delete(`${BASE_PATH}/location/${id}`),

  commitLocations: () => API.post(`${BASE_PATH}/location/commit`),
};
