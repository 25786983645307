import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { Wrapper } from "../../../components/Wrapper";
import { dataRisk } from "../../../data/dataRisk";
import { ContractId } from "../../../data/models/ContractTypes";
import { useStickySidebar } from "../hooks/useStickySidebar";
import styles from "./BackofficeCasePage.module.scss";
import clsx from "classnames";
import { RiskRejectCard } from "./components/RiskRejectCard";
import { RiskSupplementsCard } from "./components/RiskSupplementsCard";
import { Suspense } from "../../Suspense";
import Skeleton from "react-loading-skeleton";
import { RiskCaseOverviewCard } from "./components/RiskCaseOverviewCard";
import { RiskCompanyCard } from "./components/RiskCompanyCard";
import { RiskPurposeCard } from "./components/RiskPurposeCard";
import { RiskFinancialsCard } from "./components/RiskFinancialsCard";
import { RiskBankAccountCard } from "./components/RiskBankAccountCard";
import { RiskBeneficialOwnersCard } from "./components/RiskBeneficialOwnersCard";
import { RiskSignatoriesCard } from "./components/RiskSignatoriesCard";
import { RiskLocationsCard } from "./components/RiskLocationsCard";
import { RiskPrimaryContactCard } from "./components/RiskPrimaryContactCard";
import { Tabs } from "../components/Tabs/Tabs";
import { useTranslation } from "react-i18next";
import { TabView } from "../components/TabView/TabView";
import { RiskCm1Card } from "./components/RiskCm1Card";
import { RiskMatchCard } from "./components/RiskMatchCard";
import { RiskCreditsafeCard } from "./components/RiskCreditsafeCard";
import { RiskAuditHistoryCard } from "./components/RiskAuditHistoryCard";
import { RiskDocumentsCard } from "./components/RiskDocumentsCard";
import { RiskChecklistCard } from "./components/RiskChecklistCard.tsx";
import { RiskRationaleCards } from "./components/RiskRationaleCards";
import { useRiskCaseTab } from "../hooks/useRiskCaseTab";
import { RiskCaseStatus } from "./components/RiskCaseStatus";
import { LinkButton } from "../../../components/Buttons/LinkButton";
import { BACKOFFICE_DASHBOARD_ROUTE } from "../BackofficeDashboardPage/BackofficeDashboardPage";
import { FaArrowLeft } from "react-icons/fa6";
import { dataAuth } from "../../../data/dataAuth";
import { RiskClaimStatus } from "./components/RiskClaimStatus/RiskClaimStatus";

export const BACKOFFICE_CASE_ROUTE = "/backoffice/case/:id";

export enum CaseAnchor {
  // data
  PURPOSE = "purpose",
  LOCATIONS = "locations",
  // screen
  CREDITSAFE = "creditsafe",
  CM1 = "cm1",
  MATCH = "match",
}

export const BackofficeCasePage: React.FunctionComponent = () => {
  const { id } = useParams<{ id: ContractId }>();
  const [leftSidebarRef, leftSidebarIsSticky] = useStickySidebar();
  const [rightSidebarRef, rightSidebarIsSticky] = useStickySidebar();

  const { data: session } = useQuery(dataAuth.whoami());

  const { data } = useQuery(dataRisk.fetchContract(id!));

  const disabled =
    !data ||
    typeof data?.caseDetails.decision !== "undefined" ||
    session?.name !== data.caseDetails.claimedBy;

  return (
    <Wrapper>
      <div className="flex align-center justify-between">
        {data?.contract.companyDetails.name ? (
          <h1>{data.contract.companyDetails.name}</h1>
        ) : (
          <h1>
            <Skeleton width={400} />
          </h1>
        )}
        <LinkButton to={BACKOFFICE_DASHBOARD_ROUTE} variant="text">
          <FaArrowLeft /> Return to dashboard
        </LinkButton>
      </div>

      <div className={styles.container}>
        <div
          className={clsx(styles.sidebar, {
            [styles.sticky]: leftSidebarIsSticky,
          })}
          ref={leftSidebarRef}
        >
          <RiskChecklistCard disabled={disabled} />
          <RiskRejectCard disabled={disabled} />
        </div>

        <Suspense skeleton={<ContentSkeleton />}>
          <Content disabled={disabled} />
        </Suspense>

        <div
          className={clsx(styles.sidebar, {
            [styles.sticky]: rightSidebarIsSticky,
          })}
          ref={rightSidebarRef}
        >
          <RiskDocumentsCard disabled={disabled} />
          <RiskSupplementsCard
            contractLanguage={data?.contract.primaryContact.language}
            disabled={disabled}
          />
        </div>
      </div>
    </Wrapper>
  );
};

const ContentSkeleton = () => {
  return (
    <div>
      <Skeleton height={150} />
    </div>
  );
};

const Content = ({ disabled }: { disabled: boolean }) => {
  const { id } = useParams<{ id: ContractId }>();
  const { t } = useTranslation();

  const { data } = useQuery(dataRisk.fetchContract(id!));

  const [tab, setTab] = useRiskCaseTab();

  return (
    <div className="flex-column gap-3">
      <div className={styles.tabs}>
        <Tabs
          tabs={[
            {
              value: "data",
              text: t("Data"),
            },
            {
              value: "screen",
              text: t("Screening"),
            },
            {
              value: "rationale",
              text: t("Rationale"),
            },
            {
              value: "log",
              text: t("Log"),
            },
          ]}
          value={tab}
          onChange={setTab}
        />
      </div>
      <RiskCaseStatus decision={data?.caseDetails.decision} />
      <RiskClaimStatus
        disabled={!!data?.caseDetails.closed}
        claimedBy={data?.caseDetails.claimedBy}
      />
      <TabView
        index={
          tab === "data"
            ? 0
            : tab === "screen"
            ? 1
            : tab === "rationale"
            ? 2
            : 3
        }
      >
        <div className="flex-column gap-3">
          <RiskCaseOverviewCard />
          <RiskPrimaryContactCard />
          <RiskCompanyCard />
          <RiskFinancialsCard />
          <RiskBankAccountCard />
          <RiskBeneficialOwnersCard />
          <RiskSignatoriesCard />
          <div id={CaseAnchor.PURPOSE}>
            <RiskPurposeCard />
          </div>
          <div id={CaseAnchor.LOCATIONS}>
            <RiskLocationsCard disabled={disabled} />
          </div>
        </div>
        <div className="flex-column gap-3">
          <div id={CaseAnchor.CREDITSAFE}>
            <RiskCreditsafeCard />
          </div>
          <div id={CaseAnchor.MATCH}>
            <RiskMatchCard />
          </div>
          <div id={CaseAnchor.CM1}>
            <RiskCm1Card />
          </div>
        </div>
        <div className="flex-column gap-3">
          <RiskRationaleCards disabled={disabled} />
        </div>
        <div className="flex-column gap-3">
          <RiskAuditHistoryCard />
        </div>
      </TabView>
    </div>
  );
};
