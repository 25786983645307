import React, { useContext, useMemo, useRef } from "react";
import styles from "./Checkout.module.scss";
import { CheckoutSummary } from "../../../modules/Offering/Summary/CheckoutSummary";
import { Wrapper } from "../../../components/Wrapper";
import { OnboardingPath } from "../routes";
import { useTranslation } from "react-i18next";
import { useStoryNavigate } from "../../../hooks/useStoryNavigate";
import { Button } from "../../../components/Buttons/Button";
import { Form } from "../../../modules/Forms/Form";
import { GlobalContext } from "../../..";
import { Dynamic } from "../../../components/Animations/Dynamic";
import { QueryKey, SearchParams, Store, STORE_KEY } from "../../../data/Store";
import { AllowedCountries } from "../../../data/dataContract";
import { Select } from "../../../modules/Forms/Select";
import { getCountryDisplayName } from "../../../components/langUtils";
import { Country, Language } from "../../../data/models/ContractTypes";
import { Option } from "../../../components/types";
import { useSearchParams } from "react-router-dom";

function isCountryPresent(params: URLSearchParams, searchParams: SearchParams) {
  const savedValues = Store.getValue(STORE_KEY.STORE_SAVED_QUERY);
  const country =
    (params.get(QueryKey.COUNTRY)?.toUpperCase() as Country) ||
    searchParams[QueryKey.COUNTRY] ||
    savedValues[QueryKey.COUNTRY];

  return AllowedCountries.some((item) => item === country);
}

export const Checkout: React.FunctionComponent = () => {
  const [params] = useSearchParams();
  const { t, i18n } = useTranslation();
  const { navigate } = useStoryNavigate();
  const { search, setSearch } = useContext(GlobalContext);
  const hasCountry = useRef<boolean>(isCountryPresent(params, search));

  const opts = useMemo(() => {
    const items: Option<Country>[] = AllowedCountries.map((item) => ({
      value: item,
      text: getCountryDisplayName(item, i18n.language as Language),
      disabaled: false,
    }));

    items.unshift({
      value: "" as Country,
      text: t("Select country"),
      disabled: true,
    });

    return items;
  }, [i18n.language, t]);

  return (
    <Wrapper className={styles.wrapper}>
      <h1 className="text-center">{t("Your order")}</h1>

      <div className="center mb-4">
        <h2 className="mb-1">Worldline One</h2>
        {t("Acquiring + terminal")}
      </div>

      <hr />

      <div className="mb-4" />

      {hasCountry.current ? null : (
        <>
          <div className={styles.countries}>
            <p>
              {t(
                "First and foremost, we need to know where your businees are located"
              )}
            </p>

            <Select
              onChange={(value) => {
                setSearch((prev: SearchParams) => ({
                  ...prev,
                  [QueryKey.COUNTRY]: value,
                }));
                Store.setValue(STORE_KEY.STORE_SAVED_QUERY, {
                  ...search,
                  [QueryKey.COUNTRY]: value as Country,
                });
              }}
              options={opts}
              value={search[QueryKey.COUNTRY] || ""}
            />
          </div>
          <hr />
        </>
      )}

      <Dynamic name={search[QueryKey.COUNTRY] ? "yes" : "no"}>
        {search[QueryKey.COUNTRY] ? (
          <Form
            name="checkout"
            onSubmit={(_, form) => {
              if (!form.isValid) {
                return;
              }

              navigate(OnboardingPath.SIGN_IN);
            }}
          >
            <CheckoutSummary />

            <Button type="submit" block className="mt-5">
              {/* <NavigationStoryLink asButton to={OnboardingPath.SIGN_IN}>
              {t("Go to checkout")}
            </NavigationStoryLink> */}
              {t("Go to checkout")}
            </Button>
          </Form>
        ) : null}
      </Dynamic>
    </Wrapper>
  );
};
