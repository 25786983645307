import { API } from "./API";
import { Language, ValidationResult } from "./models/ContractTypes";
import { BASE_PATH } from "./proxy";

export const dataContact = {
  validateEmail: (email: string) =>
    API.post<ValidationResult>(`${BASE_PATH}/contact/validate`, {
      email,
    }),

  /**
   * Sends email with verification code
   */
  startEmailVerification: (email: string) =>
    API.post(`${BASE_PATH}/contact/email`, {
      email,
    }),

  /**
   * A correct code sets the email on primary contact
   *
   * Faulty code throws 400
   */
  verifyEmailCode: (code: string) =>
    API.post(`${BASE_PATH}/contact/confirm`, {
      code,
    }),

  savePhone: (phone: string) =>
    API.post(`${BASE_PATH}/contact/phone`, {
      phone,
    }),

  saveLanguage: (language: Language) =>
    API.post(`${BASE_PATH}/contact/language`, {
      language,
    }),
};
