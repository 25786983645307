import React, { ReactNode, useCallback, useRef } from "react";
import cx from "classnames";
import styles from "./Buttons.module.scss";
import { Button } from "../../../components/Buttons/Button";
import { HiddenInput } from "../../../modules/Forms/HiddenInput";
import { RequiredValidator } from "../../../modules/Forms/validators/RequiredValidator";
import { useTranslation } from "react-i18next";
import { FinancialValue } from "./FinancialEstimates";
import { FinancialKyc } from "../../../data/models/ContractTypes";

interface Props {
  name: keyof FinancialKyc;
  values: FinancialValue[];
  financial: FinancialKyc;
  onChange: (name: string, value: number) => void;
  children: ReactNode;
}

function getButtonVariant(buttonValue: number, selectedValue?: number) {
  return selectedValue === buttonValue ? "selected" : "outlined";
}

export const Buttons: React.FunctionComponent<Props> = ({
  name,
  values,
  financial,
  onChange,
  children,
}) => {
  const { t } = useTranslation();
  const buttonsRef = useRef<HTMLDivElement>(null);

  const onClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>, data: any) => {
      onChange(name, data.value);
    },
    [onChange, name]
  );

  return (
    <div className={cx(styles.wrapper, styles[`len-${values.length}`])}>
      {children}
      <div className={cx(styles.buttons)}>
        {values.map((item) => {
          return (
            <Button
              className={cx(styles.button)}
              onClick={onClick}
              data={{
                value: item.value,
              }}
              variant={getButtonVariant(item.value, financial[name] as number)}
            >
              {item.text}
            </Button>
          );
        })}

        <div ref={buttonsRef} className={styles.hidden}>
          <HiddenInput
            scrollToRef={buttonsRef}
            value={financial[name]}
            validators={[new RequiredValidator(t("Required value"))]}
          />
        </div>
      </div>
    </div>
  );
};
