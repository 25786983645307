import React from "react";
import { NavigationStory } from "../../components/Story/Navigation/NavigationStory";
import { Suspense } from "../../pages/Suspense";
import { AnswerStep } from "./Answer/AnswerStep";
import { CompleteStep } from "./Complete/CompleteStep";
import { SupplementPath } from "./routes";
import { StoryFrameProps } from "../../components/storyTypes";

export const SUPPLEMENT_STORY_BASE_ROUTE = "/supplement/:linkId";

export const SigningName: Record<SupplementPath, string> = {
  [SupplementPath.ANSWER]: "Answer",
  [SupplementPath.COMPLETE]: "Done",
};

export const routes: StoryFrameProps[] = [
  {
    path: SupplementPath.ANSWER,
    element: (
      <Suspense>
        <AnswerStep />
      </Suspense>
    ),
  },
  {
    path: SupplementPath.COMPLETE,
    element: (
      <Suspense>
        <CompleteStep />
      </Suspense>
    ),
  },
];

export const SupplementStory: React.FunctionComponent = () => {
  return (
    <NavigationStory routes={routes} baseRoute={SUPPLEMENT_STORY_BASE_ROUTE} />
  );
};
