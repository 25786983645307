import React, { ReactNode, useCallback } from "react";
import styles from "./StoryButtons.module.scss";
import { useStoryNavigate } from "../hooks/useStoryNavigate";
import { Button } from "../components/Buttons/Button";
import { Path } from "../components/storyTypes";
import { Status } from "../modules/Forms/FormContext";
import { useTranslation } from "react-i18next";
import { MdArrowBack, MdArrowForward } from "react-icons/md";

interface Props {
  backButton?: string | ReactNode;
  nextButton?: string | ReactNode;
  disableBack?: boolean;
  disabled?: boolean;
  action?:
    | Path
    | ((event: React.MouseEvent<HTMLButtonElement>, data: any) => void);
}

interface ButtonProps
  extends Omit<Props, "disabled" | "backButton" | "nextButton"> {
  status: Status;
  button: ReactNode;
}

export const StoryButtons: React.FunctionComponent<Props> = (props) => {
  const { disabled, backButton, nextButton } = props;
  const status = disabled ? Status.DISABLED : Status.DEFAULT;
  const { t } = useTranslation();

  return (
    <div className={styles.buttons}>
      <div>
        <BackButton
          {...props}
          status={status}
          button={
            backButton || (
              <>
                <MdArrowBack /> {t("Back")}
              </>
            )
          }
        />
      </div>

      <div>
        <OnSubmitButton
          {...props}
          status={status}
          button={
            nextButton || (
              <>
                {t("Next")} <MdArrowForward />
              </>
            )
          }
        />
        <OnClickButton
          {...props}
          status={status}
          button={
            nextButton || (
              <>
                {t("Next")} <MdArrowForward />
              </>
            )
          }
        />
        <OnNavButton
          {...props}
          status={status}
          button={
            nextButton || (
              <>
                {t("Next")} <MdArrowForward />
              </>
            )
          }
        />
      </div>
    </div>
  );
};

const BackButton: React.FunctionComponent<ButtonProps> = ({
  disableBack = false,
  button,
  status,
}) => {
  const { onBack } = useStoryNavigate();

  if (disableBack) {
    return null;
  }

  if (!onBack) {
    return null;
  }

  return (
    <Button onClick={onBack} variant="outlined" status={status} noStatusIcon>
      {button}
    </Button>
  );
};

const OnNavButton: React.FunctionComponent<ButtonProps> = ({
  action,
  button,
  status,
}) => {
  const { navigate } = useStoryNavigate();

  const onNavigate = useCallback(() => {
    if (typeof action !== "string") {
      return;
    }

    navigate(action);
  }, [action, navigate]);

  if (typeof action !== "string") {
    return null;
  }

  return (
    <Button onClick={onNavigate} status={status} noStatusIcon>
      {button}
    </Button>
  );
};

const OnClickButton: React.FunctionComponent<ButtonProps> = ({
  action,
  button,
  status,
}) => {
  if (typeof action !== "function") {
    return null;
  }

  return (
    <Button onClick={action} status={status} noStatusIcon>
      {button}
    </Button>
  );
};

const OnSubmitButton: React.FunctionComponent<ButtonProps> = ({
  action,
  button,
  status,
}) => {
  if (action) {
    return null;
  }

  return (
    <Button type="submit" status={status} noStatusIcon>
      {button}
    </Button>
  );
};
