import React, { useState, useMemo, useCallback } from "react";
import styles from "./Bankgiro.module.scss";
import Kontonummer from "kontonummer";
import { Wrapper } from "../../../../components/Wrapper";
import { useContract } from "../../../../hooks/useContract";
import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import { dataBank } from "../../../../data/dataBank";
import { useStoryNavigate } from "../../../../hooks/useStoryNavigate";
import { OnboardingPath } from "../../routes";
import { Form } from "../../../../modules/Forms/Form";
import { TextInput } from "../../../../modules/Forms/TextInput";
import { RequiredValidator } from "../../../../modules/Forms/validators/RequiredValidator";
import { Dynamic } from "../../../../components/Animations/Dynamic";
import {
  LegalEntityType,
  Validity,
} from "../../../../data/models/ContractTypes";
import { GenericError } from "../../../../components/Errors/GenericError";
import { Beacon } from "../../../../components/Beacon/Beacon";
import { BankValidatorMinLength } from "../../../../modules/Forms/validators/BankValidatorMinLength";
import { BankValidatorMaxLength } from "../../../../modules/Forms/validators/BankValidatorMaxLength";
import { SavedAccount } from "../SavedAccount";
import { StoryButtons } from "../../../StoryButtons";
import { View } from "../../../../modules/View/View";
import { Columns } from "../../../../components/Columns/Columns";

const MIN_LENGTH_ACCOUNT = 7;
const MAX_LENGTH_ACCOUNT = 10;
const MIN_LENGTH_CLEARING = 4;
const MAX_LENGTH_CLEARING = 5;

function cleanNumber(numberAsString?: string) {
  if (!numberAsString) {
    return "";
  }

  return numberAsString.replace(/\D/g, "");
}

export const Bankgiro: React.FunctionComponent = () => {
  const contract = useContract();
  const { t } = useTranslation();
  const [bankAccount, setBankAccount] = useState<string>("");
  const [clearing, setClearing] = useState<string>("");
  const [info, setInfo] = useState<Kontonummer | null>(null);
  const [hasShowedWarning, setHasShowedWarning] = useState<boolean>(false);
  // const queryClient = useQueryClient();
  const { navigate } = useStoryNavigate();

  const showBankName = useMemo(() => {
    return (
      !!info &&
      cleanNumber(clearing).length + cleanNumber(bankAccount).length >=
        MIN_LENGTH_CLEARING + MIN_LENGTH_ACCOUNT
    );
  }, [info, clearing, bankAccount]);

  const {
    mutate: onSave,
    isPending,
    isError,
    reset,
  } = useMutation({
    mutationFn: () => {
      return dataBank.queueToSwedishBGC(
        cleanNumber(clearing) + cleanNumber(bankAccount)
      );
    },
    onSuccess: () => {
      if (
        contract.companyDetails.legalEntityType ===
        LegalEntityType.SOLE_PROPRIETARY
      ) {
        navigate(OnboardingPath.SUMMARY);
      } else {
        navigate(OnboardingPath.OWNERS);
      }
    },
  });

  const next = useCallback(() => {
    if (
      contract.companyDetails.legalEntityType ===
      LegalEntityType.SOLE_PROPRIETARY
    ) {
      navigate(OnboardingPath.SUMMARY);
    } else {
      navigate(OnboardingPath.OWNERS);
    }
  }, [contract, navigate]);

  return (
    <Wrapper>
      <Form
        name="bank-account"
        onSubmit={(_, form) => {
          if (!form.isValid) {
            return;
          }

          if (!info?.valid && !hasShowedWarning) {
            setHasShowedWarning(true);
            return;
          }

          setHasShowedWarning(false);
          onSave();
        }}
      >
        <View
          header={t("Payout account")}
          fixedValidity={
            contract.bankAccount?.iban ? Validity.VALID : undefined
          }
        >
          <p>
            {t(
              "Enter your bank account where you wish to recieve your payouts, the bank account must be owned by the company."
            )}
          </p>

          <SavedAccount bankAccount={contract.bankAccount} next={next} />

          <div className="mt-6">
            <p>
              <b>{t("Example")}:</b> 3300 7810251111
            </p>
            <Columns className="mt-4">
              <div>
                <TextInput
                  name="clearing"
                  label={t("Clearing number")}
                  value={clearing}
                  disabled={isPending}
                  validators={[
                    new RequiredValidator("Required"),
                    new BankValidatorMinLength(
                      MIN_LENGTH_CLEARING,
                      t("At least {{min}} digits are required", {
                        min: MIN_LENGTH_CLEARING,
                      })
                    ),
                    new BankValidatorMaxLength(
                      MAX_LENGTH_CLEARING,
                      t("Not more than {{max}} digits are allowed", {
                        max: MAX_LENGTH_CLEARING,
                      })
                    ),
                  ]}
                  onChange={(value) => {
                    reset();
                    setClearing(value);
                    setHasShowedWarning(false);
                    try {
                      const info = Kontonummer.parse(value, bankAccount || "", {
                        mode: "strict",
                      });
                      setInfo(info);
                    } catch (err) {
                      setInfo(null);
                    }
                  }}
                  inputAttributes={{
                    inputMode: "numeric",
                  }}
                />
              </div>
              <div>
                <TextInput
                  name="bankAccount"
                  label={t("Account number")}
                  value={bankAccount}
                  disabled={isPending}
                  validators={[
                    new RequiredValidator(t("Required")),
                    new BankValidatorMinLength(
                      MIN_LENGTH_ACCOUNT,
                      t("At least {{min}} digits are required", {
                        min: MIN_LENGTH_ACCOUNT,
                      })
                    ),
                    new BankValidatorMaxLength(
                      MAX_LENGTH_ACCOUNT,
                      t("Not more than {{max}} digits are allowed", {
                        max: MAX_LENGTH_ACCOUNT,
                      })
                    ),
                  ]}
                  onChange={(value) => {
                    reset();
                    setBankAccount(value);
                    setHasShowedWarning(false);
                    try {
                      const info = Kontonummer.parse(clearing, value, {
                        mode: "strict",
                      });
                      setInfo(info);
                    } catch (err) {
                      setInfo(null);
                    }
                  }}
                  inputAttributes={{
                    inputMode: "numeric",
                  }}
                />
              </div>
            </Columns>
          </div>

          <Dynamic name={showBankName ? "account" : ""}>
            {info && showBankName ? (
              <div className={styles.info}>
                <Beacon className="mini" validity={Validity.VALID} />{" "}
                &nbsp;&nbsp;
                <b>{info.bankName}</b> - {`${info.format("pretty")} `}
              </div>
            ) : null}
          </Dynamic>

          <Dynamic name={hasShowedWarning ? "warning" : ""}>
            {hasShowedWarning ? (
              <div className={styles.warning}>
                {t(
                  "Bank accounts are sometimes hard to verify. In this case, we are unable to verify the entered account number. Please check the account number again. If it looks good, you may proceed."
                )}
              </div>
            ) : null}
          </Dynamic>

          <Dynamic name={isError ? "error" : ""}>
            {isError ? (
              <div className={styles.error}>
                <GenericError />
              </div>
            ) : null}
          </Dynamic>

          <div className="mt-6">
            <StoryButtons disabled={isPending} />
          </div>
        </View>
      </Form>
    </Wrapper>
  );
};
