import React from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { InfoList } from "../../../../components/List/InfoList";
import { Contract } from "../../../../data/models/ContractTypes";
import styles from "../SummarySection.module.scss";

interface Props {
  contract: Contract;
}

export const ContactDetails: React.FunctionComponent<Props> = ({
  contract,
}) => {
  const { t } = useTranslation();
  const { name, phone, email } = contract.primaryContact;

  return (
    <div className={cx(styles.wrapper, styles.sectionPadding)}>
      <InfoList>
        <InfoList.Dt>{t("Name")}</InfoList.Dt>
        <InfoList.Dd>{name}</InfoList.Dd>
        <InfoList.Dt>{t("Phone")}</InfoList.Dt>
        <InfoList.Dd>{phone}</InfoList.Dd>
        <InfoList.Dt>{t("Invoice email")}</InfoList.Dt>
        <InfoList.Dd>{email}</InfoList.Dd>
      </InfoList>
    </div>
  );
};
