import { FaCommentDots } from "react-icons/fa6";
import { useRiskCase } from "../../hooks/useRiskCase";
import { useTranslation } from "react-i18next";
import { BackofficeCard } from "../../components/card/BackofficeCard";
import { BackofficeCardHeader } from "../../components/card/BackofficeCardHeader";
import Skeleton from "react-loading-skeleton";
import { useState } from "react";
import { Form } from "../../../../modules/Forms/Form";
import { TextArea } from "../../../../modules/Forms/TextArea";
import { Button } from "../../../../components/Buttons/Button";
import { Status } from "../../../../modules/Forms/FormContext";
import { Suspense } from "../../../Suspense";
import { useMutation } from "@tanstack/react-query";
import { dataRisk } from "../../../../data/dataRisk";
import { useContractId } from "../../hooks/useContractId";
import { useDebouncedStatus } from "../../hooks/useDebouncedStatus";

interface Props {
  disabled: boolean;
}

export const RiskRationaleCards: React.FC<Props> = ({ disabled }) => {
  return (
    <Suspense
      skeleton={
        <div className="flex-column gap-3">
          <BackofficeCard>
            <Skeleton height={100} />
          </BackofficeCard>
          <BackofficeCard>
            <Skeleton height={100} />
          </BackofficeCard>
        </div>
      }
    >
      <Inner disabled={disabled} />
    </Suspense>
  );
};

const Inner: React.FC<Props> = ({ disabled }) => {
  const contractId = useContractId();
  const { t } = useTranslation();

  const {
    data: {
      caseDetails: { finalComment, businessComment },
    },
  } = useRiskCase();

  const { mutateAsync: updatePurpose, status: purposeMutationStatus } =
    useMutation(dataRisk.updatePurposeRationale(contractId));

  const {
    mutateAsync: updateFinalComment,
    status: finalCommentMutationStatus,
  } = useMutation(dataRisk.updateFinalComment(contractId));

  const purposeStatus = useDebouncedStatus(purposeMutationStatus, {
    disabled,
  });

  const finalCommentStatus = useDebouncedStatus(finalCommentMutationStatus, {
    disabled,
  });

  return (
    <div className="flex-column gap-4">
      <BackofficeCard>
        <BackofficeCardHeader
          title={t("Business Purpose and Nature")}
          icon={FaCommentDots}
        />
        <RationaleItem
          value={businessComment || ""}
          label={t("Comment")}
          onChange={(value) => updatePurpose(value)}
          status={purposeStatus}
        />
      </BackofficeCard>
      <BackofficeCard>
        <BackofficeCardHeader title={t("Final comment")} icon={FaCommentDots} />
        <RationaleItem
          value={finalComment || ""}
          label={t("Comment")}
          onChange={(value) => updateFinalComment(value)}
          status={finalCommentStatus}
        />
      </BackofficeCard>
    </div>
  );
};

interface RationaleItemProps {
  value: string;
  label: string;
  onChange?: (value: string) => Promise<void>;
  status?: Status;
}

const RationaleItem: React.FC<RationaleItemProps> = ({
  value,
  label,
  onChange,
  status,
}) => {
  const [edit, setEdit] = useState(false);
  const [editedValue, setEditedValue] = useState("");
  const { t } = useTranslation();

  const onReset = () => {
    setEdit(false);
    setEditedValue(value);
  };

  const onEdit = () => {
    setEdit(true);
    setEditedValue(value);
  };

  if (edit) {
    return (
      <Form
        name={label}
        onSubmit={async (_, form) => {
          if (form.isInvalid) {
            return;
          }
          onChange && (await onChange(editedValue));
          setEdit(false);
          // TODO: save
        }}
      >
        <TextArea
          name={label}
          value={editedValue}
          onChange={setEditedValue}
          // validators={[new RequiredValidator(t("This field is required"))]}
          attributes={{ rows: 6 }}
        />
        <div className="flex gap-2">
          <Button onClick={onReset} block variant="outlined" size="small">
            {t("Cancel")}
          </Button>
          <Button type="submit" status={status} block size="small">
            {t("Save")}
          </Button>
        </div>
      </Form>
    );
  }

  return (
    <div>
      <TextArea
        value={value}
        onChange={() => {}}
        attributes={{ rows: 6 }}
        disabled
      />
      {onChange && status !== Status.DISABLED && (
        <Button
          onClick={onEdit}
          status={status}
          variant="outlined"
          block
          size="small"
        >
          {t("Edit")}
        </Button>
      )}
    </div>
  );
};
