import { useCallback, useContext, useMemo } from "react";
import { generatePath, useLocation, useNavigate } from "react-router-dom";
import { cleanPath, getIndex } from "../components/utils";
import { useLinkId } from "./useLinkId";
import { Direction, Path, StoryFrameProps } from "../components/storyTypes";
import { NavigationStoryContext } from "../components/Story/Navigation/NavigationStory";

function getRoute(baseRoute: string, path: string): string {
  return cleanPath(`${baseRoute}${path}`);
}

export function useStoryNavigate() {
  const navigate = useNavigate();
  const location = useLocation();
  const linkId = useLinkId();
  const { setDirection, setFilteredRoutes, filteredRoutes, routes, baseRoute } =
    useContext(NavigationStoryContext);

  const currentRoute = useMemo(() => {
    const paths = location.pathname.split("/");
    const currentRoute = `/${paths.pop() || ""}`;
    const currentIndex = getIndex(currentRoute, filteredRoutes);
    return {
      currentIndex,
      currentRoute,
    };
  }, [filteredRoutes, location.pathname]);

  const updateRoutes = useCallback(
    (updateRoutes: StoryFrameProps[]) => {
      setFilteredRoutes(updateRoutes);
    },
    [setFilteredRoutes]
  );

  const onNav = useCallback(
    (to: Path) => {
      setDirection(
        currentRoute.currentIndex > getIndex(to, filteredRoutes)
          ? Direction.BACKWARDS
          : Direction.FORWARDS
      );

      setTimeout(() => {
        window.requestAnimationFrame(() => {
          navigate(`${getRoute(generatePath(baseRoute, { linkId }), to)}`);
        });
      }, 0);
    },
    [
      navigate,
      currentRoute.currentIndex,
      linkId,
      filteredRoutes,
      baseRoute,
      setDirection,
    ]
  );

  const updateRoutesAndNavigate = useCallback(
    (updateRoutes: StoryFrameProps[], to: Path) => {
      setFilteredRoutes(updateRoutes);
      onNav(to);
    },
    [setFilteredRoutes, onNav]
  );

  const onBack = useCallback(() => {
    onNav(filteredRoutes[Math.max(currentRoute.currentIndex - 1, 0)].path);
  }, [filteredRoutes, currentRoute.currentIndex, onNav]);

  const onNext = useCallback(() => {
    const paths = location.pathname.split("/");
    const currentRoute = `/${paths.pop() || ""}`;
    const currentIndex = getIndex(currentRoute, filteredRoutes);
    if (!filteredRoutes[currentIndex + 1]) {
      throw new Error("onNext: No next link found");
    }
    onNav(filteredRoutes[currentIndex + 1].path);
  }, [filteredRoutes, location.pathname, onNav]);

  return {
    navigate: onNav,
    updateRoutes,
    updateRoutesAndNavigate,
    filteredRoutes,
    originalRoutes: routes,
    onBack: currentRoute.currentIndex === 0 ? undefined : onBack,
    onNext:
      currentRoute.currentIndex === filteredRoutes.length - 1
        ? undefined
        : onNext,
  };
}
