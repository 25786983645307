import React from "react";
import cx from "classnames";
import styles from "../SummarySection.module.scss";
import { useTranslation } from "react-i18next";
import { InfoList } from "../../../../components/List/InfoList";
import { Contract } from "../../../../data/models/ContractTypes";
import { FlagAndCountry } from "../../../../modules/Countries/FlagAndCountry";

interface Props {
  contract: Contract;
}

export const CompanyDataConfirm: React.FunctionComponent<Props> = ({
  contract,
}) => {
  const { t } = useTranslation();
  const { organizationNumber, name, street, postalCode, city } =
    contract.companyDetails;

  return (
    <div className={cx(styles.wrapper, styles.sectionPadding)}>
      <h3>{name}</h3>
      <InfoList>
        <InfoList.Dt>{t("Organization identifier")}</InfoList.Dt>
        <InfoList.Dd>{organizationNumber}</InfoList.Dd>
        <InfoList.Dt>{t("Address")}</InfoList.Dt>
        <InfoList.Dd>{street}</InfoList.Dd>
        <InfoList.Dt>{t("Postal code")}</InfoList.Dt>
        <InfoList.Dd>{postalCode}</InfoList.Dd>
        <InfoList.Dt>{t("City")}</InfoList.Dt>
        <InfoList.Dd>{city}</InfoList.Dd>
        <InfoList.Dt>{t("Country")}</InfoList.Dt>
        <InfoList.Dd>
          <FlagAndCountry countryCode={contract.country} />
        </InfoList.Dd>
      </InfoList>
    </div>
  );
};
