import { FaPeopleGroup } from "react-icons/fa6";
import { useRiskCase } from "../../hooks/useRiskCase";
import { useTranslation } from "react-i18next";
import { BackofficeCard } from "../../components/card/BackofficeCard";
import { BackofficeCardHeader } from "../../components/card/BackofficeCardHeader";
import { DataList } from "../../components/Datalist/DataList";
import { DataListItem } from "../../components/Datalist/DataListItem";
import { Suspense } from "../../../Suspense";
import Skeleton from "react-loading-skeleton";
import { Fragment } from "react/jsx-runtime";

export const RiskBeneficialOwnersCard: React.FC = () => {
  const { t } = useTranslation();

  return (
    <BackofficeCard>
      <BackofficeCardHeader
        title={t("Beneficial owners")}
        icon={FaPeopleGroup}
        verified="Roaring"
      />
      <Suspense skeleton={<Skeleton height={100} className="mb-2" />}>
        <Inner />
      </Suspense>
    </BackofficeCard>
  );
};

const Inner = () => {
  const { t } = useTranslation();

  const {
    data: { contract },
  } = useRiskCase();
  const { beneficialOwners } = contract;

  return (
    <div>
      {beneficialOwners.map((owner, index, acc) => (
        <Fragment key={owner.id}>
          <DataList type="horizontal" align="left" className="mb-2">
            <DataListItem label={t("Name")} value={owner.name} />
            <DataListItem
              label={t("Personal identity number")}
              value={owner.nationalId}
            />
            <DataListItem label={t("Nationality")} value={owner.nationality} />
            <DataListItem
              label={t("Date of birth")}
              value={owner.dateOfBirth}
            />
            <DataListItem
              label={t("Place of birth")}
              value={owner.placeOfBirth}
            />
            <DataListItem
              label={t("Citizenship(s)")}
              value={owner.citizenships.join(", ")}
            />
            <DataListItem
              label={t("Country of residence")}
              value={owner.countryOfResidence}
            />
          </DataList>
          <DataList type="vertical" align="left" key={owner.id} columns>
            <DataListItem
              label={t("Ownership percentage")}
              value={
                typeof owner.capitalStake !== "undefined"
                  ? `${owner.capitalStake}%`
                  : undefined
              }
              column
            />
            <DataListItem
              label={t("Voting rights percentage")}
              value={
                typeof owner.votingRightsStake !== "undefined"
                  ? `${owner.votingRightsStake}%`
                  : undefined
              }
              column
            />
          </DataList>
          {index !== acc.length - 1 && <hr />}
        </Fragment>
      ))}
    </div>
  );
};
