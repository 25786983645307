import { useSuspenseQuery } from "@tanstack/react-query";
import { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaFilter, FaMagnifyingGlass } from "react-icons/fa6";
import Skeleton from "react-loading-skeleton";
import { Dynamic } from "../../components/Animations/Dynamic";
import { Button } from "../../components/Buttons/Button";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverRef,
} from "../../components/Popover/Popover";
import { dataRisk } from "../../data/dataRisk";
import { TextInput } from "../../modules/Forms/TextInput";
import { Suspense } from "../Suspense";
import styles from "./BackofficeSidebar.module.scss";
import { CaseItem } from "./components/CaseItem/CaseItem";

export const BackofficeSidebar: React.FunctionComponent = () => {
  const { t } = useTranslation();

  const [searchActive, setSearchActive] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [hideWaitingSupplements, setHideWaitingSupplements] = useState(false);
  const popoverRef = useRef<PopoverRef>(null);

  const activeFilterCount = [!hideWaitingSupplements].filter(Boolean).length;

  return (
    <div className={styles.wrapper}>
      <div className={styles.logo}>
        <img src="/images/worldline-logo.svg" alt="Worldline logo" />
      </div>
      <div>
        <div className="flex gap-1 justify-between">
          <Popover ref={popoverRef} bordered>
            <PopoverTrigger>
              <div className={styles.filterButtonWrapper}>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={(e) => {
                    e.stopPropagation();
                    popoverRef.current?.toggle();
                  }}
                >
                  {t("Filter")} <FaFilter />
                </Button>
                {activeFilterCount > 0 && (
                  <span className={styles.activeFilters}>
                    {activeFilterCount}
                  </span>
                )}
              </div>
            </PopoverTrigger>
            <PopoverContent>
              <div className={styles.filterWrapper}>
                <div className="mb-2">
                  <Button
                    size="small"
                    variant={hideWaitingSupplements ? "outlined" : "primary"}
                    onClick={() => {
                      setHideWaitingSupplements(!hideWaitingSupplements);
                      popoverRef.current?.close();
                    }}
                    block
                  >
                    Show waiting for supplements
                  </Button>
                </div>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => {
                    setHideWaitingSupplements(false);
                    popoverRef.current?.close();
                  }}
                >
                  {t("Reset filters")}
                </Button>
                <Button
                  size="small"
                  variant="text"
                  onClick={() => popoverRef.current?.close()}
                >
                  {t("Close")}
                </Button>
              </div>
            </PopoverContent>
          </Popover>
          <Button
            size="small"
            variant="text"
            onClick={() => {
              setSearchQuery("");
              setSearchActive(!searchActive);
            }}
          >
            <FaMagnifyingGlass />
          </Button>
        </div>

        <Dynamic name={searchActive ? "searchActive" : "searchInactive"}>
          {searchActive && (
            <div
              style={{
                marginBottom: "calc(var(--spacing-4) * -1)",
                marginTop: "var(--spacing-2)",
              }}
            >
              <TextInput
                placeholder={t("Search")}
                onChange={setSearchQuery}
                value={searchQuery}
                onClear={() => {
                  setSearchQuery("");
                  setSearchActive(false);
                }}
              />
            </div>
          )}
        </Dynamic>
      </div>

      <div className={styles.content}>
        <Suspense skeleton={<CaseListSkeleton />}>
          <CaseList
            searchQuery={searchQuery}
            hideWaitingSupplements={hideWaitingSupplements}
          />
        </Suspense>
      </div>
    </div>
  );
};

const CaseList: React.FunctionComponent<{
  searchQuery: string;
  hideWaitingSupplements: boolean;
}> = ({ searchQuery, hideWaitingSupplements }) => {
  const { data } = useSuspenseQuery(dataRisk.fetchQueue());

  const filteredData = useMemo(() => {
    return data?.filter((item) => {
      // First apply supplements filter
      if (hideWaitingSupplements) {
        if (item.answeredSupplements !== item.requestedSupplements) {
          return false;
        }
      }

      // Then apply search filter
      if (!searchQuery) return true;
      const searchLower = searchQuery.toLowerCase();
      return (
        item.organizationNumber?.toLowerCase().includes(searchLower) ||
        item.companyName?.toLowerCase().includes(searchLower)
      );
    });
  }, [data, searchQuery, hideWaitingSupplements]);

  return (
    <ul className={styles.caseList}>
      {filteredData?.map((item) => (
        <CaseItem key={item.id} item={item} />
      ))}
    </ul>
  );
};

const CaseListSkeleton = () => {
  return (
    <div className="flex-column gap-1">
      {Array.from({ length: 10 }).map((_, index) => (
        <Skeleton height={70} key={index} />
      ))}
    </div>
  );
};
