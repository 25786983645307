import clsx from "classnames";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../components/Buttons/Button";
import { getIntlDateTime } from "../../../../components/dateUtils";
import { DocumentTypeLabel } from "../../../../components/DocumentTypeLabel/DocumentTypeLabel";
import { RiskDocument } from "../../../../data/models/RiskTypes";
import { RiskDocumentOverlay } from "./RiskDocumentOverlay";
import styles from "./RiskDocumentRow.module.scss";

interface Props {
  document: RiskDocument;
  disabled: boolean;
}

export const RiskDocumentRow: React.FC<Props> = ({ document }) => {
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <RiskDocumentOverlay
        isOpen={isPreviewOpen}
        onClose={() => setIsPreviewOpen(false)}
        document={document}
        disabled={false}
      />
      <div className={styles.container}>
        <div className="truncated">
          <div className="truncated text-500 text-09">
            {document.documentDescription || (
              <DocumentTypeLabel value={document.documentType} />
            )}
          </div>
          <span className={clsx("text-08 passive text-600")}>
            {getIntlDateTime(document.created)}
          </span>
        </div>
        <Button
          onClick={() => setIsPreviewOpen(true)}
          size="mini"
          variant="text"
          className={styles.button}
        >
          {t("View")}
        </Button>
      </div>
    </>
  );
};
