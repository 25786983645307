import React, {
  useState,
  useCallback,
  RefObject,
  useRef,
  useMemo,
  useId,
} from "react";
import cx from "classnames";
import { InputProps } from "./Input";
import { BaseValidator } from "./validators/BaseValidator";
import { getConstraints, isDefined } from "./TextInput";
import { useValidation } from "./hooks/useValidation";
import { useForm } from "./hooks/useForm";
import { InputWrapper } from "./InputWrapper";
import styles from "./Select.module.scss";
import { Option, OptionWithId } from "../../components/types";
import { FaChevronDown } from "react-icons/fa6";

export interface SelectProps<T>
  extends Omit<
    InputProps,
    | "formatInputValues"
    | "state"
    | "name"
    | "constraints"
    | "type"
    | "onClear"
    | "value"
    | "onChange"
    | "onBlur"
  > {
  onChange: (
    value: T,
    name: string,
    ev: React.ChangeEvent<HTMLSelectElement>
  ) => void;
  options: Option<T>[];
  onBlur?: (
    value: T,
    name: string,
    ev: React.ChangeEvent<HTMLSelectElement>
  ) => void;
  value?: T;
  scrollToRef?: RefObject<HTMLElement>;
  name?: string;
  validators?: BaseValidator[];
  clearable?: boolean;
}

// export const Select = React.forwardRef(Inner);

export function Select<T>(props: SelectProps<T>) {
  const {
    className,
    name: originalName,
    onChange,
    onBlur,
    options = [],
    validators = [],
    value,
    scrollToRef,
    disabled,
    autocomplete,
    placeholder,
  } = props;

  const identifier = useId();
  const emptyIdentifier = useRef<string>("");
  const name = originalName || identifier;
  const innerRef = useRef<HTMLSelectElement>(null);
  const [showErrors, setShowErrors] = useState<boolean>(isDefined(value));

  const [validity, errorMessages, showFormErrors] = useValidation<T>(
    value,
    validators
  );

  useForm(identifier, validity, value, () => {}, scrollToRef ?? innerRef);

  const error = useMemo(() => {
    if (errorMessages.length > 0 && (showErrors || showFormErrors)) {
      return errorMessages[0];
    }

    return;
  }, [errorMessages, showErrors, showFormErrors]);

  const constraints = getConstraints(validators);

  const optionsWithIds = useMemo(() => {
    return options.map((alternative, idx): OptionWithId<T> => {
      const id = `${identifier}-${idx}-${Math.random()
        .toString(36)
        .substring(2, 9)}`;

      if (!alternative.value) {
        emptyIdentifier.current = id;
      }

      return {
        ...alternative,
        id,
      };
    });
  }, [identifier, options]);

  const change = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const currentAltWithId = optionsWithIds.find(
        (alternative) => alternative.id === event.target.value
      );
      currentAltWithId && onChange(currentAltWithId.value, name, event);
    },
    [name, onChange, optionsWithIds]
  );

  const blur = useCallback(
    (ev: React.ChangeEvent<HTMLSelectElement>) => {
      if (isDefined(ev.target.value)) {
        setShowErrors(true);
      }
      onBlur && onBlur(ev.target.value as any, name, ev);
    },
    [name, onBlur]
  );

  const opts = useMemo(() => {
    return optionsWithIds.map((alternative) => (
      <option
        key={`${alternative.text}-${alternative.value}`}
        value={alternative.id}
        data-value={alternative.value}
        disabled={alternative.disabled}
      >
        {alternative.text}
      </option>
    ));
  }, [optionsWithIds]);

  const selectedValue =
    optionsWithIds.find((alternative) => alternative.value === value)?.id ||
    emptyIdentifier.current;

  return (
    <InputWrapper
      {...props}
      value={selectedValue}
      className={cx(className, styles.select)}
      state={validity}
      constraints={constraints}
      name={name}
      message={error}
    >
      <select
        value={selectedValue}
        onChange={change}
        onBlur={blur}
        ref={innerRef}
        autoComplete={autocomplete}
        required={constraints.isRequired}
        disabled={disabled}
      >
        {placeholder ? (
          <option
            value={emptyIdentifier.current}
            disabled={value !== emptyIdentifier.current}
          >
            {placeholder}
          </option>
        ) : null}
        {opts}
      </select>
      <div className={cx(styles.expand, "action-imitation", "small")}>
        <FaChevronDown />
      </div>
    </InputWrapper>
  );
}
