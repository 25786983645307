import React, { createContext, useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import "./index.scss";
import "./i18n";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";
import { Onboarding } from "./stories/Onboarding/Onboarding";
import { useTranslation } from "react-i18next";
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import * as Sentry from "@sentry/react";
import { APP_ID, ServerError } from "./data/API";
import "react-loading-skeleton/dist/skeleton.css";
import { Access } from "./data/proxy";
import { TestPage } from "./pages/TestPage";
import {
  BackofficeRoot,
  BACKOFFICE_BASE_ROUTE,
} from "./pages/backoffice/BackofficeRoot";
import { SearchParams } from "./data/Store";
import { dataAuth } from "./data/dataAuth";
import { dataRisk } from "./data/dataRisk";
import {
  SupplementStory,
  SUPPLEMENT_STORY_BASE_ROUTE,
} from "./stories/Supplement/SupplementStory";
import { Signing, SIGNING_BASE_ROUTE } from "./stories/Signing/Signing";

Sentry.init({
  dsn: "https://a37e1626683a49afaed7f8fd788a0026@o4504866465185792.ingest.sentry.io/4504876975325184",
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracesSampleRate: 0.5,
  enabled: process.env.NODE_ENV === "production",
  environment: process.env.REACT_APP_ENV,
});

Sentry.setContext("session", {
  appId: APP_ID,
});

export function isDev() {
  return process.env.REACT_APP_ENV === "development";
}

export function isProd() {
  return process.env.REACT_APP_ENV === "production";
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

export const UNAUTHORIZED_STATUS = 401;
const DEFAULT_NUMBER_OF_RETRIES = 4;

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      networkMode: "always",
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: (failureCount, err) => {
        if (err && (err as ServerError<any>).status === UNAUTHORIZED_STATUS) {
          return false; // do not retry, trigger error
        }

        return failureCount < DEFAULT_NUMBER_OF_RETRIES - 1;
      },
      retryDelay: isDev()
        ? 500
        : (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
    },
  },
  queryCache: new QueryCache({
    onError: async (error, { queryKey }) => {
      if (!(error instanceof ServerError)) {
        return;
      }
      if (error.status !== 401) {
        return;
      }

      // if is risk endpoint, invalidate whoami
      if (queryKey.includes(dataRisk.QUERY_KEY)) {
        queryClient.invalidateQueries({
          queryKey: dataAuth.whoami().queryKey,
        });
      }
    },
  }),
});

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export interface GlobalProps {
  search: SearchParams;
  setSearch: (params: Record<string, any>) => void;
  access: Access;
  setAccess: (access: Access) => void;
}

export const GlobalContext = createContext<GlobalProps>({
  search: {},
  setSearch: () => {},
  access: Access.VIEW_AND_EDIT,
  setAccess: () => {},
});

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Lang />
      <App />
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

function App() {
  const [search, setSearch] = useState<SearchParams>({});
  const [access, setAccess] = useState<Access>(Access.VIEW_AND_EDIT);

  return (
    <GlobalContext.Provider
      value={{
        search,
        setSearch,
        access,
        setAccess,
      }}
    >
      <Sentry.ErrorBoundary showDialog={false}>
        <Router>
          <SentryRoutes>
            {/* TODO: Remove test-page in the future */}
            <Route path="/test-page" element={<TestPage />} />
            <Route
              path={`${BACKOFFICE_BASE_ROUTE}/*`}
              element={<BackofficeRoot />}
            />
            <Route
              path={`${SUPPLEMENT_STORY_BASE_ROUTE}/*`}
              element={<SupplementStory />}
            />
            <Route path={`${SIGNING_BASE_ROUTE}/*`} element={<Signing />} />
            <Route path="*" element={<Onboarding />} />
          </SentryRoutes>
        </Router>
      </Sentry.ErrorBoundary>
    </GlobalContext.Provider>
  );
}

function Lang() {
  const { i18n } = useTranslation();

  useEffect(() => {
    const dir = i18n.dir(i18n.language);
    document.documentElement.dir = dir;
  }, [i18n, i18n.language]);

  return null;
}
