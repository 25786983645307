import React, { useState, useEffect } from "react";
import cx from "classnames";
import styles from "./Signing.module.scss";
import { Wrapper } from "../../../components/Wrapper";
import { useStoryNavigate } from "../../../hooks/useStoryNavigate";
import { useTranslation } from "react-i18next";
import { Button } from "../../../components/Buttons/Button";
import { Form } from "../../../modules/Forms/Form";
import { HiddenInput } from "../../../modules/Forms/HiddenInput";
import { Checkboxes } from "../../../modules/Forms/Checkboxes";
import {
  dataSigning,
  QUERY_KEY,
  QUERY_RESULT_ABORTED,
  QUERY_RESULT_SUCCESS,
} from "../../../data/dataSigning";
import { UseMutateFunction, useMutation } from "@tanstack/react-query";
import { OnboardingPath } from "../routes";
import { Dynamic } from "../../../components/Animations/Dynamic";
import { GenericError } from "../../../components/Errors/GenericError";
import { Status } from "../../../modules/Forms/FormContext";
import { useSearchParams } from "react-router-dom";
import { RequiredValidator } from "../../../modules/Forms/validators/RequiredValidator";
import { View } from "../../../modules/View/View";
import { isProd } from "../../..";

export const Signing: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const [hasAgreed, setHasAgreed] = useState<boolean>();
  const { navigate } = useStoryNavigate();
  const [searchParams] = useSearchParams();
  const signingStatus = searchParams.get(QUERY_KEY);

  useEffect(() => {
    if (signingStatus === QUERY_RESULT_SUCCESS) {
      navigate(OnboardingPath.COMPLETE);
      return;
    }
  }, [signingStatus, navigate]);

  const {
    mutate: getSigningLink,
    isPending,
    isError,
  } = useMutation({
    mutationFn: () => dataSigning.loadPrimaryContactSignLink(),
    onSuccess: (data) => {
      if (data.signed) {
        navigate(OnboardingPath.COMPLETE);
        return;
      }

      window.location.href = data.signUrl;
    },
  });

  const { mutate: fakeSign } = useMutation({
    mutationFn: () => dataSigning.fakeSign(),
    onSuccess: () => {
      navigate(OnboardingPath.COMPLETE);
    },
  });

  const buttonStatus = isPending ? Status.DISABLED : Status.DEFAULT;

  return (
    <Wrapper>
      <Form
        onSubmit={(_, form) => {
          if (form.isInvalid) {
            return;
          }

          getSigningLink();
        }}
      >
        <View header={t("Sign")} size="small" indicator={false}>
          <Checkboxes
            onChange={() => {
              setHasAgreed((prev) => !prev);
            }}
            values={hasAgreed ? [true] : []}
            options={[
              {
                value: true,
                text: t(
                  "I have reviewed and confirm that all information is correct"
                ),
              },
            ]}
          />

          <HiddenInput
            value={hasAgreed ? [true] : undefined}
            validators={[
              new RequiredValidator(
                t("You must confirm the information before signing")
              ),
            ]}
          />

          <Dynamic name={isError.toString()}>
            {isError ? <GenericError /> : null}
          </Dynamic>

          <Dynamic name={signingStatus || "empty"}>
            {signingStatus === QUERY_RESULT_ABORTED ? <GenericError /> : null}
          </Dynamic>

          <div className={cx(styles.signButton)}>
            <Button
              type="submit"
              variant="outlined"
              block
              status={buttonStatus}
            >
              {t("Sign")}
            </Button>
          </div>
          <div className="mt-15">
            <FakeSign fakeSign={fakeSign} />
          </div>
        </View>
      </Form>
    </Wrapper>
  );
};

export const FakeSign: React.FunctionComponent<{
  fakeSign: UseMutateFunction<unknown, Error, void, unknown>;
}> = ({ fakeSign }) => {
  if (isProd()) {
    return null;
  }

  return (
    <div className={styles.fakeSign}>
      <p>
        <b>Debug</b>
      </p>
      <Button
        onClick={() => {
          fakeSign();
        }}
        block
      >
        Fake signature for all signatories
      </Button>
    </div>
  );
};
