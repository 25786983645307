import React from "react";
import { useTranslation } from "react-i18next";
import { Wrapper } from "../../../components/Wrapper";
import { HiddenInput } from "../../../modules/Forms/HiddenInput";
import { useLinkId } from "../../../hooks/useLinkId";
import { dataSigning } from "../../../data/dataSigning";
import { useSuspenseQueries } from "@tanstack/react-query";
import { RequiredValidator } from "../../../modules/Forms/validators/RequiredValidator";
import { View } from "../../../modules/View/View";
import { Form } from "../../../modules/Forms/Form";
import { AllSigned } from "./AllSigned";
import { Waiting } from "./Waiting";

export const Complete: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const linkId = useLinkId();

  const [{ data: signingPerson }] = useSuspenseQueries({
    queries: [dataSigning.fetchSigningStatus(linkId)],
  });

  const allSigned = signingPerson.signees.every((signee) => signee.signed);

  return (
    <Wrapper>
      <Form>
        <HiddenInput
          value={allSigned ? "All good" : undefined}
          validators={[new RequiredValidator("")]}
        />

        <h1>{t("What happens now?")}</h1>

        <View header={t("Next steps")} size="small">
          {allSigned ? <AllSigned /> : <Waiting />}
        </View>
      </Form>
    </Wrapper>
  );
};
