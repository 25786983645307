import React, { useState, ReactNode, useCallback } from "react";
import cx from "classnames";
import styles from "./SummarySection.module.scss";
import { MdCheckCircle, MdChevronLeft } from "react-icons/md";
import { ContractProgress } from "../../../data/models/ContractTypes";
import { OnboardingName } from "../Onboarding";
import { ProgressMap } from "../SignIn/SignIn";
import { Accordion } from "../../../components/Animations/Accordion";
import { useTranslation } from "react-i18next";
import { Button } from "../../../components/Buttons/Button";
import { useStoryNavigate } from "../../../hooks/useStoryNavigate";
import bg from "../../../components/bg.svg";

interface Props {
  phase: ContractProgress;
  children: ReactNode;
  number: number;
}

export const SummarySection: React.FunctionComponent<Props> = ({
  phase,
  children,
  number,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const name = OnboardingName[ProgressMap[phase]];
  const { navigate } = useStoryNavigate();

  const onNav = useCallback(() => {
    navigate(ProgressMap[phase]);
  }, [navigate, phase]);

  return (
    <section
      className={styles.section}
      style={{ backgroundImage: `url(${bg})` }}
    >
      <button
        className={cx(styles.top, {
          [styles.open]: open,
        })}
        onClick={() => setOpen((prev) => !prev)}
      >
        <div className={styles.name}>
          <MdCheckCircle />
          <span>
            {t(`${number}`)}. {name}
          </span>
        </div>
        <div className={styles.toggle}>
          <MdChevronLeft />
        </div>
      </button>

      <div className={styles.bodyWrapper}>
        <Accordion active={open}>
          <div className={styles.body}>
            <hr className={styles.divider} />
            {children}
          </div>

          <div className="mt-5">
            <Button onClick={onNav} block variant="outlined">
              {t("Edit")}
            </Button>
          </div>
        </Accordion>
      </div>
    </section>
  );
};
