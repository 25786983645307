import React from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { InfoList } from "../../../../components/List/InfoList";
import { Contract } from "../../../../data/models/ContractTypes";
import { ProductImage } from "../../../../components/ProductOffer/ProductOffer";
import styles from "../SummarySection.module.scss";
import specific from "./LocationSetup.module.scss";

interface Props {
  contract: Contract;
}

export const LocationSetup: React.FunctionComponent<Props> = ({ contract }) => {
  const { t } = useTranslation();
  const {
    doingBusinessAs,
    storePhoneNumber,
    street,
    city,
    postalCode,
    terminals,
  } = contract.locations[0];

  // mcc?: string;
  // doingBusinessAs?: string;
  // storePhoneNumber?: string;
  // street?: string;
  // city?: string;
  // postalCode?: string;
  // terminalPreset: TerminalPreset;
  // terminals: Terminal[];

  return (
    <div className={cx(styles.wrapper, styles.sectionPadding)}>
      <InfoList>
        <InfoList.Dt>{t("Doing business as")}</InfoList.Dt>
        <InfoList.Dd>{doingBusinessAs}</InfoList.Dd>
        <InfoList.Dt>{t("Contact Phone")}</InfoList.Dt>
        <InfoList.Dd>{storePhoneNumber}</InfoList.Dd>
        <InfoList.Dt>{t("Address")}</InfoList.Dt>
        <InfoList.Dd>{street}</InfoList.Dd>
        <InfoList.Dt>{t("Zip code")}</InfoList.Dt>
        <InfoList.Dd>{postalCode}</InfoList.Dd>
        <InfoList.Dt>{t("City")}</InfoList.Dt>
        <InfoList.Dd>{city}</InfoList.Dd>
      </InfoList>

      {terminals
        .filter((terminal) => terminal.count)
        .map((item) => {
          return (
            <div key={item.model} className={specific.product}>
              <div className={specific.image}>
                <ProductImage terminalType={item.model} />
              </div>
              <div className={specific.info}>
                {item.model}
                <div className={specific.quantity}>
                  {t("Quantity")}: <span>{item.count}</span>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};
