// TODO, update flow so that not all views are
// shown to every customer, e.g. Sole Proprietor

export enum OnboardingPath {
  PRODUCT_SELECTION = "/product-selection",
  CHECKOUT = "/checkout",
  SIGN_IN = "/",
  INFORMATION = "/information",
  CONTACT = "/contact",
  SALES_LOCATIONS = "/sales-location",
  MCC = "/industry",
  DOCUMENT_UPLOAD = "/documents",
  PRODUCTS = "/bundle",
  FINANCIAL = "/financial",
  FINANCIAL_ESTIMATES = "/financial-estimates",
  BUSINESS_RISK = "/risk",
  BANK_ACCOUNT = "/bank",
  OWNERS = "/owners",
  SIGNING_GROUPS = "/signing-groups",
  SIGNATORIES = "/signatories",
  SUMMARY = "/summary",
  SIGNING = "/signing",
  COMPLETE = "/complete",
}
