import React from "react";
import cx from "classnames";
import styles from "./AllSigned.module.scss";
import { useTranslation } from "react-i18next";
import { useContract } from "../../../hooks/useContract";
import { ContractProgress } from "../../../data/models/ContractTypes";
import { Confirmed, TimelineStatus } from "./Timeline/Confirmed";
import { Signatories } from "./Timeline/Signatories";
import { Verified } from "./Timeline/Verified";
import { Shipped } from "./Timeline/Shipped";

export const AllSigned: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const contract = useContract();
  const inReview = contract.contractProgress === ContractProgress.REVIEW;
  const inProvisioning =
    contract.contractProgress === ContractProgress.PROVISIONING;
  const isComplete = contract.contractProgress === ContractProgress.COMPLETE;
  return (
    <div className={cx(styles.allSigned)}>
      <p>
        {t(
          "Great! All signatories have signed. We will check the provided information and then ship the terminals."
        )}
      </p>

      <Confirmed status={TimelineStatus.DONE} />
      <Signatories status={TimelineStatus.DONE} />
      <Verified
        status={
          isComplete || inProvisioning
            ? TimelineStatus.DONE
            : inReview
            ? TimelineStatus.ACTIVE
            : TimelineStatus.NOT_STARTED
        }
      />
      <Shipped
        status={
          isComplete
            ? TimelineStatus.DONE
            : inProvisioning
            ? TimelineStatus.ACTIVE
            : TimelineStatus.NOT_STARTED
        }
      />
    </div>
  );
};
