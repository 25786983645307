import { API } from "./API";
import { BASE_PATH } from "./proxy";

//Den här kan utökas när jag kommer på flera felscenarion
export enum StartOnboaringError {
  //Validation error
  BAD_ORG_NR = "BAD_ORG_NR",
  COMPANY_NOT_FOUND = "COMPANY_NOT_FOUND",
  COMPANY_INACTIVE = "COMPANY_INACTIVE",
  NOT_ELIGIBLE_TYPE = "NOT_ELIGIBLE_TYPE",
  NO_BENIFCIAL_OWNERS = "NO_BENIFCIAL_OWNERS",
  NO_SIGNEES = "NO_SIGNEES",
}

export interface StartOnboardingResponse {
  success: boolean;
  errorType?: StartOnboaringError;
}

export interface CompanySelection {
  companyName: string;
  organizationNumber: string;
  role: string;
}

export interface AvailableCompanies {
  active?: CompanySelection;
  //These are always empty in Finland and Denmark as of now (2024)
  valid: CompanySelection[];
  invalid: CompanySelection[];
}

//Requires login
export const dataStartOnboarding = {
  getCompanySelection: () => API.get<AvailableCompanies>(`${BASE_PATH}/start`),

  getCompanySelectionKey: (): string[] => {
    return ["companies"];
  },

  fetchCompanySelection: () => ({
    queryKey: dataStartOnboarding.getCompanySelectionKey(),
    queryFn: () => dataStartOnboarding.getCompanySelection(),
  }),

  startOnboarding: (organizationNumber: string) => {
    return API.post<StartOnboardingResponse>(`${BASE_PATH}/start`, {
      organizationNumber,
    });
  },

  cancelOngoingOnboarding: () => API.post(`${BASE_PATH}/cancel`),

  //TODO contact sales endpoint
};
