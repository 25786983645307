import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import cx from "classnames";
import styles from "./SignIn.module.scss";
import {
  AvailableCompanies,
  CompanySelection,
  dataStartOnboarding,
  StartOnboaringError,
} from "../../../data/dataStartOnboarding";
import {
  SelectableList,
  SelectableListItem,
} from "../../../modules/Forms/SelectableList";
import { RequiredValidator } from "../../../modules/Forms/validators/RequiredValidator";
import { RegexValidator } from "../../../modules/Forms/validators/RegexValidator";
import { useMutation, useSuspenseQueries } from "@tanstack/react-query";
import { Status } from "../../../modules/Forms/FormContext";
import { useTranslation } from "react-i18next";
import { Wrapper } from "../../../components/Wrapper";
import { IdentificationWrapper } from "./Identification";
import { MdCheckCircle } from "react-icons/md";
import { Or } from "../../../components/Or/Or";
import { useStoryNavigate } from "../../../hooks/useStoryNavigate";
import { OnboardingPath } from "../routes";
import { GlobalContext, queryClient } from "../../..";
import { TextInput } from "../../../modules/Forms/TextInput";
import { Form, FormContainer } from "../../../modules/Forms/Form";
import { HiddenInput } from "../../../modules/Forms/HiddenInput";
import { ContractProgress, Country } from "../../../data/models/ContractTypes";
import { TFunction } from "i18next";
import { QueryKey } from "../../../data/Store";
import { dataContract } from "../../../data/dataContract";
import { Path } from "../../../components/storyTypes";
import { StatusError } from "../../../components/Errors/StatusError";

interface OrgNumber {
  hint: string;
  regex: RegExp;
}

function getInitialOrgNumber(
  companies: AvailableCompanies
): string | undefined {
  const { active, valid } = companies;

  if (active) {
    return active.organizationNumber;
  }

  if (valid.length === 1) {
    return valid[0].organizationNumber;
  }
}

function checkMatchingOrgNumber(
  companyOrgNumber: string,
  enteredOrgNumber?: string
): string | undefined {
  if (companyOrgNumber === enteredOrgNumber) {
    return companyOrgNumber;
  }

  if (companyOrgNumber === enteredOrgNumber?.replaceAll("-", "")) {
    return companyOrgNumber;
  }
}

function getOrgNumber(country: Country, t: TFunction): OrgNumber {
  // TODO
  switch (country) {
    case Country.SWEDEN:
      return {
        hint: t('E.g "{{hint}}"', {
          hint: "556677-4433",
        }),
        regex: /^\d{6}-\d{4}$/,
      };

    case Country.DENMARK:
      return {
        hint: t('E.g "{{hint}}"', {
          hint: "36729929",
        }),
        regex: /^[0-9]{8}$/,
      };

    default:
      break;
  }

  return {
    hint: t('E.g "{{hint}}"', {
      hint: "556677-4433",
    }),
    regex: /^\d{6}-\d{4}$/,
  };
}

export const ProgressMap: Record<ContractProgress, OnboardingPath> = {
  [ContractProgress.COMPANY_DATA_CONFIRM]: OnboardingPath.INFORMATION,
  [ContractProgress.CONTACT_DETAILS]: OnboardingPath.CONTACT,
  [ContractProgress.LOCATION_SETUP]: OnboardingPath.SALES_LOCATIONS,
  [ContractProgress.FINANCIAL_KYC]: OnboardingPath.FINANCIAL,
  [ContractProgress.BUSINESS_RISK]: OnboardingPath.BUSINESS_RISK,
  [ContractProgress.PAYOUT_ACCOUNT]: OnboardingPath.BANK_ACCOUNT,
  [ContractProgress.BENEFICIAL_OWNER]: OnboardingPath.OWNERS,
  [ContractProgress.SIGNATORIES]: OnboardingPath.SIGNATORIES,
  [ContractProgress.CONFIRM_CONTRACT]: OnboardingPath.SUMMARY,
  [ContractProgress.SIGNING]: OnboardingPath.SIGNING,
  [ContractProgress.REVIEW]: OnboardingPath.COMPLETE,
  [ContractProgress.PROVISIONING]: OnboardingPath.COMPLETE,
  [ContractProgress.COMPLETE]: OnboardingPath.COMPLETE,
};

export function whereTo(
  progress: ContractProgress,
  navigate: (to: Path) => void
) {
  navigate(ProgressMap[progress]);
}

function getValid(companies: AvailableCompanies): CompanySelection[] {
  return companies.valid.filter(
    (company) =>
      company.organizationNumber !== companies.active?.organizationNumber
  );
}

export const SignIn: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const formRef = useRef<FormContainer>();
  const { navigate } = useStoryNavigate();
  const { search } = useContext(GlobalContext);
  const country = search[QueryKey.COUNTRY] as Country;

  const [{ data: companies }] = useSuspenseQueries({
    queries: [dataStartOnboarding.fetchCompanySelection()],
  });

  const [status, setStatus] = useState<Status | StartOnboaringError>(
    Status.DEFAULT
  );

  const [selectedOrg, setSelectedOrg] = useState<string | undefined>(
    getInitialOrgNumber(companies)
  );
  const [enteredOrg, setEnteredOrg] = useState<string>();

  const {
    isPending,
    mutate: onStart,
    reset: resetStartError,
    isError,
  } = useMutation({
    mutationFn: () => {
      const id = enteredOrg || selectedOrg;
      if (!id) {
        return Promise.reject();
      }

      return dataStartOnboarding.startOnboarding(id);
    },
    onSuccess: async (data) => {
      if (data.success) {
        await queryClient.invalidateQueries({
          queryKey: [...dataStartOnboarding.getCompanySelectionKey()],
          refetchType: "all",
        });
        const data = await queryClient.fetchQuery(dataContract.fetchContract());
        whereTo(data.contractProgress, navigate);
        return;
      }

      data.errorType ? setStatus(data.errorType) : setStatus(Status.ERROR);
    },
  });

  const {
    isError: isCancelError,
    reset: resetCancelError,
    isPending: isCancelPending,
    mutateAsync: onCancel,
  } = useMutation({
    mutationFn: async () => dataStartOnboarding.cancelOngoingOnboarding(),
  });

  const onNav = useCallback(async () => {
    if (formRef.current?.isInvalid) {
      formRef.current.setForceErrors(true);
      return;
    }

    if (!companies.active) {
      onStart();
      return;
    }

    if (selectedOrg === companies.active?.organizationNumber) {
      try {
        const data = await queryClient.fetchQuery(dataContract.fetchContract());
        whereTo(data.contractProgress, navigate);
      } catch (error) {
        console.log(error);
        setStatus(Status.ERROR);
      }
      return;
    }

    try {
      await onCancel();
      onStart();
    } catch (err) {
      console.error("err", err);
      setStatus(Status.ERROR);
    }
  }, [navigate, companies, selectedOrg, onStart, onCancel]);

  const onSelect = useCallback(
    (orgNumber: string) => {
      setStatus(Status.DEFAULT);
      resetStartError();
      resetCancelError();
      setSelectedOrg(orgNumber);
      setEnteredOrg("");
    },
    [resetCancelError, resetStartError]
  );

  const onEnteredOrg = useCallback(
    (orgNumber: string) => {
      setStatus(Status.DEFAULT);
      resetStartError();
      resetCancelError();
      setEnteredOrg(orgNumber);
      const orgNumberItem = getOrgNumber(country, t);
      const correctlyFormattedOrgNumber = orgNumber.match(orgNumberItem.regex);

      if (!correctlyFormattedOrgNumber) {
        setSelectedOrg("");
        return;
      }

      let found = false;

      if (companies.active) {
        const activeNumber = checkMatchingOrgNumber(
          companies.active.organizationNumber,
          orgNumber
        );
        found = true;
        setSelectedOrg(activeNumber);
      }

      for (const company of companies.valid) {
        const activeNumber = checkMatchingOrgNumber(
          company.organizationNumber,
          orgNumber
        );

        if (activeNumber) {
          found = true;
          setSelectedOrg(activeNumber);
        }
      }

      if (!found) {
        setSelectedOrg("");
      }
    },
    [t, companies, country, resetCancelError, resetStartError]
  );

  const buttonStatus = useMemo(() => {
    if (isPending || isCancelPending) {
      return Status.PENDING;
    }

    if (isAnOnboardingError(status)) {
      return Status.ERROR;
    }

    return status as Status;
  }, [status, isCancelPending, isPending]);

  const consolidatedStatus = useMemo(() => {
    if (isAnOnboardingError(status)) {
      return Status.ERROR;
    }

    if (isError || isCancelError) {
      return Status.ERROR;
    }

    return status;
  }, [status, isError, isCancelError]);

  const disabled = buttonStatus === Status.PENDING;

  return (
    <Wrapper>
      <div className="mt-5">
        <IdentificationWrapper onNext={onNav} status={buttonStatus}>
          <Active
            companies={companies}
            selectedOrg={selectedOrg}
            onSelect={onSelect}
            disabled={disabled}
          />
          <Valid
            companies={companies}
            selectedOrg={selectedOrg}
            onSelect={onSelect}
            disabled={disabled}
          />
          <Form formContainer={formRef}>
            <OrgNumberInput
              companies={companies}
              enteredOrg={enteredOrg}
              onEnteredOrg={onEnteredOrg}
              country={country}
              disabled={disabled}
            />
            <HiddenInput
              value={selectedOrg || enteredOrg ? true : undefined}
              validators={[
                new RequiredValidator(
                  t(
                    "You must select an organization or enter a valid organization id"
                  )
                ),
              ]}
            />
          </Form>
          {
            // TODO - Missing, and warning if changing org
          }

          <div className="mt-6">
            <StartOnboardingStatusMessage status={consolidatedStatus} />
          </div>
        </IdentificationWrapper>
      </div>
    </Wrapper>
  );
};

const OrgNumberInput: React.FunctionComponent<{
  companies: AvailableCompanies;
  enteredOrg?: string;
  onEnteredOrg: (value: string) => void;
  country: Country;
  disabled?: boolean;
}> = ({ enteredOrg, disabled, companies, onEnteredOrg, country }) => {
  let ingress = null;
  const { t } = useTranslation();

  if (companies.active) {
    ingress = (
      <div className="mt-5">
        <Or className="mb-4" />
        <b>{t("Enter the organization identifier")}</b>
      </div>
    );
  } else if (companies.valid.length) {
    ingress = (
      <div className="mt-5">
        <Or className="mb-4" />
        <b>{t("Enter the organization identifier")}</b>
      </div>
    );
  } else {
    ingress = (
      <div className="mt-5">
        <b>{t("Enter the organization identifier")}</b>
      </div>
    );
  }

  const orgNumber = getOrgNumber(country, t);

  return (
    <>
      {ingress}
      <TextInput
        disabled={disabled}
        className="mt-2"
        value={enteredOrg}
        onChange={(value) => {
          onEnteredOrg(value);
        }}
        validators={[
          new RegexValidator(
            orgNumber.regex,
            t("Organization identifier is incorrectly formatted")
          ),
        ]}
        hint={orgNumber.hint}
      />
    </>
  );
};

const Valid: React.FunctionComponent<{
  companies: AvailableCompanies;
  selectedOrg?: string;
  onSelect: (orgNumber: string) => void;
  disabled?: boolean;
}> = ({ companies, disabled, selectedOrg, onSelect }) => {
  const { t } = useTranslation();
  const { active } = companies;
  const valid = getValid(companies);

  if (!valid.length) {
    return null;
  }

  let ingress;

  if (active) {
    ingress = (
      <div className="mt-5">
        <Or className="mb-4" />
        <b>{t("Go ahead with")}</b>
      </div>
    );
  } else if (valid.length === 1) {
    ingress = (
      <>
        <b>{t("Good news!")}</b>
        <div>{t("We found a company connected to you.")}</div>
      </>
    );
  } else {
    ingress = (
      <>
        <div className={styles.valid}>
          <div className={styles.icon}>
            <MdCheckCircle />
          </div>
          <div>
            <b>{t("Good news!")}</b>{" "}
            {t("We found several companies connected to you.")}
          </div>
        </div>
        <div className="mt-5">
          <b>{t("Select a company to go forward with")}</b>
        </div>
      </>
    );
  }

  return (
    <>
      {ingress}
      <SelectableList
        verticalAlignment="center"
        className="mt-2"
        onChange={onSelect}
        value={selectedOrg}
        disabled={disabled}
      >
        {valid.map((company) => {
          return (
            <SelectableListItem
              key={company.organizationNumber}
              value={company.organizationNumber}
            >
              {company.companyName}
              <div className={cx(styles.org)}>{company.organizationNumber}</div>
            </SelectableListItem>
          );
        })}
      </SelectableList>
    </>
  );
};

const Active: React.FunctionComponent<{
  companies: AvailableCompanies;
  selectedOrg?: string;
  onSelect: (orgNumber: string) => void;
  disabled?: boolean;
}> = ({ companies, disabled, selectedOrg, onSelect }) => {
  const { active } = companies;

  const { t } = useTranslation();

  if (!active) {
    return null;
  }

  return (
    <>
      <div className={styles.valid}>
        <p>
          <b>{t("Good news!")}</b>{" "}
          {t(
            "There already is a contract in progress. Continue were we left off, shall we?"
          )}
        </p>
      </div>

      <SelectableList
        verticalAlignment="center"
        onChange={onSelect}
        value={selectedOrg}
        disabled={disabled}
      >
        <SelectableListItem
          key={active.organizationNumber}
          value={active.organizationNumber}
        >
          {active.companyName}
          <div className={cx(styles.org)}>{active.organizationNumber}</div>
        </SelectableListItem>
      </SelectableList>
    </>
  );
};

function isAnOnboardingError(status?: Status | StartOnboaringError): boolean {
  return (
    !!status &&
    Object.values(StartOnboaringError).includes(status as StartOnboaringError)
  );
}

const StartOnboardingStatusMessage: React.FunctionComponent<{
  status: Status | StartOnboaringError | boolean;
}> = ({ status }) => {
  const { t } = useTranslation();

  return (
    <>
      <StatusError status={status as Status} />
      <StatusError status={status === StartOnboaringError.BAD_ORG_NR}>
        {t("Entered an incorrect organization identifier")}
      </StatusError>
      <StatusError status={status === StartOnboaringError.COMPANY_NOT_FOUND}>
        {t("We couldn't find this company")}
      </StatusError>
      <StatusError status={status === StartOnboaringError.COMPANY_INACTIVE}>
        {t("This company is no longer active")}
      </StatusError>
      <StatusError status={status === StartOnboaringError.NOT_ELIGIBLE_TYPE}>
        {t(
          "At the moment we can't handle online onboardings for this legal entity type"
        )}
      </StatusError>
      <StatusError status={status === StartOnboaringError.NO_BENIFCIAL_OWNERS}>
        {t("There are no beneficial owners associated with this company")}
      </StatusError>
      <StatusError status={status === StartOnboaringError.NO_SIGNEES}>
        {t("There are no signatories associated with this company")}
      </StatusError>
    </>
  );
};
