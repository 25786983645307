import React from "react";
import { Wrapper } from "../../../components/Wrapper";
import { useContract } from "../../../hooks/useContract";
import { Form } from "../../../modules/Forms/Form";
import { HiddenInput } from "../../../modules/Forms/HiddenInput";
import { RequiredValidator } from "../../../modules/Forms/validators/RequiredValidator";
import { useTranslation } from "react-i18next";
import { View } from "../../../modules/View/View";
import { AllSigned } from "./AllSigned";
import { Waiting } from "./Waiting";

export const Complete: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const contract = useContract();

  const allSigned = contract.selectedSignatories.every(
    (signee) => signee.signed
  );

  return (
    <Wrapper>
      <Form>
        <HiddenInput
          value={allSigned ? "All good" : undefined}
          validators={[new RequiredValidator("")]}
        />

        <h1>{t("What happens now?")}</h1>

        <View header={t("Next steps")} size="small">
          {allSigned ? <AllSigned /> : <Waiting />}
        </View>
      </Form>
    </Wrapper>
  );
};
