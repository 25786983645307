import React, { ReactNode } from "react";
import cx from "classnames";
import styles from "./ProductOffer.module.scss";
import {
  Country,
  Currency,
  ProductItem,
  TerminalType,
} from "../../data/models/ContractTypes";
import { Image } from "../Image/Image";
import { useTranslation } from "react-i18next";
import { formattedPrice } from "../FormattedPrice/FormattedPrice";
import { QuantityInput } from "../../modules/Forms/QuantityInput";
import { Dynamic } from "../Animations/Dynamic";

interface Props {
  terminalType: TerminalType;
  quantity: number;
  price: number | undefined;
  currency: string;
  voucher?: ReactNode;
  onClick?: (quantity: number) => void;
  showBrands?: boolean;
  country: Country;
}

export const ProductOffer: React.FunctionComponent<Props> = ({
  terminalType,
  price,
  currency,
  voucher = null,
  showBrands = false,
  country,
  onClick,
  quantity,
}) => {
  const { t, i18n } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <div className={styles.productWrapper}>
        <div
          className={cx(styles.thumbnail, {
            [styles.tone]: quantity === 0,
          })}
        >
          <ProductImage terminalType={terminalType} />
        </div>
        <div className={styles.info}>
          <div>
            <b className="text-12">{ProductItem[terminalType].name}</b>

            <div className="text-500">
              {t("{{price}}/month", {
                price: price
                  ? formattedPrice(price, currency, i18n.language)
                  : "-",
              })}
              {voucher && <div className="mt-1">{voucher}</div>}
            </div>
            <Dynamic name={quantity === 0 ? "no" : "yes"}>
              {quantity === 0 ? null : (
                <div>
                  <div className="passive mt-1 text-09">
                    <div>
                      {t("Binding time: {{months}} months", {
                        months: ProductItem[terminalType].bindingTime,
                      })}
                    </div>
                    <div>
                      {t("Start fee: {{price}}", {
                        price: formattedPrice(
                          ProductItem[terminalType].startFee,
                          Currency[country],
                          i18n.language
                        ),
                      })}
                    </div>
                  </div>
                </div>
              )}
            </Dynamic>
          </div>
          <div>
            <QuantityInput
              min={0}
              value={quantity}
              onChange={(quantity) => {
                onClick && onClick(quantity);
              }}
              name={`${terminalType}-quantity`}
              hideMessages
            />
          </div>
          {showBrands && (
            <div className={cx(styles.cardBrands, "mt-2")}>
              <img src="https://placehold.it/40x30" alt="Card brand" />
              <img src="https://placehold.it/40x30" alt="Card brand" />
              <img src="https://placehold.it/40x30" alt="Card brand" />
              <img src="https://placehold.it/40x30" alt="Card brand" />
              <img src="https://placehold.it/40x30" alt="Card brand" />
              <img src="https://placehold.it/40x30" alt="Card brand" />
              <img src="https://placehold.it/40x30" alt="Card brand" />
              <img src="https://placehold.it/40x30" alt="Card brand" />
            </div>
          )}
          {/* <div className="text-08 passive">
          <TerminalDescription terminalType={terminalType} />
        </div> */}
        </div>
      </div>
    </div>
  );
};

export const ProductImage: React.FunctionComponent<{
  terminalType: TerminalType;
}> = ({ terminalType }) => {
  return (
    <div className={styles.productImage}>
      <Image {...ProductItem[terminalType]} />
    </div>
  );
};
