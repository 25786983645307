import { FaPaperclip } from "react-icons/fa6";
import Skeleton from "react-loading-skeleton";
import { Suspense } from "../../../Suspense";
import { BackofficeCard } from "../../components/card/BackofficeCard";
import { BackofficeCardHeader } from "../../components/card/BackofficeCardHeader";
import { useContractId } from "../../hooks/useContractId";
import { Status } from "../../../../modules/Forms/FormContext";
import { Button } from "../../../../components/Buttons/Button";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { RiskDocumentRow } from "./RiskDocumentRow";
import { RiskAddDocumentOverlay } from "./RiskAddDocumentOverlay";
import { useSuspenseQuery } from "@tanstack/react-query";
import { dataRisk } from "../../../../data/dataRisk";

interface Props {
  disabled: boolean;
}

export const RiskDocumentsCard: React.FC<Props> = ({ disabled }) => {
  const [isRequesting, setIsRequesting] = useState(false);
  const { t } = useTranslation();

  return (
    <BackofficeCard>
      <RiskAddDocumentOverlay
        isOpen={isRequesting}
        onClose={() => setIsRequesting(false)}
      />

      <BackofficeCardHeader title={t("Attachments")} icon={FaPaperclip} />

      <div className="mb-2">
        <Suspense skeleton={<Skeleton height={100} />}>
          <Inner disabled={disabled} />
        </Suspense>
      </div>

      <Button
        size="small"
        variant="outlined"
        block
        onClick={() => setIsRequesting(true)}
        status={disabled ? Status.DISABLED : undefined}
      >
        {t("Add attachment")}
      </Button>
    </BackofficeCard>
  );
};

const Inner = ({ disabled }: { disabled: boolean }) => {
  const contractId = useContractId();
  const { t } = useTranslation();

  const { data: documents } = useSuspenseQuery(
    dataRisk.fetchAllDocuments(contractId)
  );

  return (
    <>
      {documents.length === 0 ? (
        <p className="passive">{t("There are no attachments.")}</p>
      ) : (
        <div className="flex-column gap-1 mb-2">
          {documents.map((attachment) => (
            <RiskDocumentRow
              document={attachment}
              key={attachment.documentId}
              disabled={disabled}
            />
          ))}
        </div>
      )}
    </>
  );
};
