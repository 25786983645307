import React, {
  useState,
  useCallback,
  useMemo,
  useRef,
  useEffect,
} from "react";
import cx from "classnames";
import styles from "./Owner.module.scss";
import {
  BeneficialOwner,
  Contract,
  Country,
  CountryCode,
  Language,
} from "../../../data/models/ContractTypes";
import {
  Form,
  FormContainer,
  FormEvent,
  FormEventType,
} from "../../../modules/Forms/Form";
import { useTranslation } from "react-i18next";
import { Select } from "../../../modules/Forms/Select";
import { useContract } from "../../../hooks/useContract";
import { useMutation } from "@tanstack/react-query";
import { dataAssociates } from "../../../data/dataAssociates";
import { dataContract } from "../../../data/dataContract";
import { queryClient } from "../../..";
import { Dynamic } from "../../../components/Animations/Dynamic";
import { TextInput } from "../../../modules/Forms/TextInput";
import { RequiredValidator } from "../../../modules/Forms/validators/RequiredValidator";
import { getCountryOptions } from "../../../components/Countries/countryOptions";
import { AssociateAccordion } from "../../../modules/AssociateAccordion/AssociateAccordion";
import { applicableNationalities } from "../../../modules/SignatoryNationality/SignatoryNationality";
import { MinLengthValidator } from "../../../modules/Forms/validators/MinLengthValidator";
import { MaxLengthValidator } from "../../../modules/Forms/validators/MaxLengthValidator";
import { isSweden } from "../../../data/models/ContractUtils";
import { HiddenInput } from "../../../modules/Forms/HiddenInput";
import { onNextFrame } from "../../../components/utils";
import { Citizenships } from "../../../modules/SignatoryForm/Citizenships";

interface Props {
  owner: BeneficialOwner;
}

type Validation = {
  hint: string;
  placeholder: string;
};

export const Validations: Record<Country, Validation> = {
  [Country.SWEDEN]: {
    hint: "ÅÅÅÅMMDDNNNN",
    placeholder: "199901319999", // 12 chars
  },
  [Country.FINLAND]: {
    hint: "DDMMÅÅSNNNC", // Personbeteckningen, henkilötunnus
    placeholder: "310199Y001T", // 11 chars
  },
  [Country.DENMARK]: {
    hint: "DDMMÅÅNNNN", // CPR-nummer
    placeholder: "3101990001", // 10 chars
  },
  [Country.NORWAY]: {
    hint: "DDMMÅÅNNNKK", // Fødselsnummer
    placeholder: "31019912345", // 11 chars
  },

  [Country.GB]: {
    // Not applicable
    hint: "xxxxxxxxxxxx",
    placeholder: "xxxxxxxxxxxx",
  },
};

const MAX_AGE = 80;

function getMinDateOfBirth() {
  const dateString = new Date().toISOString();
  const yyyymmdd = dateString.split("T")[0];
  const year = new Date().getFullYear() - MAX_AGE;
  return `${year}${yyyymmdd.substring(4)}`;
}

function getMaxDateOfBirth() {
  const dateString = new Date().toISOString();
  return dateString.split("T")[0];
}

export const Owner: React.FunctionComponent<Props> = ({
  owner: originalOwner,
}) => {
  const formRef = useRef<FormContainer>();
  const contract = useContract();
  const { t, i18n } = useTranslation();
  const [owner, setOwner] = useState<BeneficialOwner>(originalOwner);
  const queryKey = dataContract.getContractKey();
  const latestShownIdInput = useRef<"socialSecurityNumber" | "dateOfBirth">();
  const [open, setOpen] = useState<boolean>(
    contract.beneficialOwners.length === 1
  );

  useEffect(() => {
    const onUpdate = (event: FormEvent, container: FormContainer) => {
      if (
        event.type === FormEventType.FORCE_ERRORS &&
        event.value &&
        container.isInvalid
      ) {
        setOpen(true);
      }
    };

    const container = formRef.current;
    container?.addListener(onUpdate);
    return () => {
      container?.removeListener(onUpdate);
    };
  }, []);

  const onChange = useCallback(
    (value: string, name: string) => {
      setOwner((prev) => {
        if (Array.isArray(owner[name as keyof BeneficialOwner])) {
          return {
            ...prev,
            [name]: (owner[name as keyof BeneficialOwner] as string[]).concat(
              value
            ),
          };
        }

        return { ...prev, [name]: value };
      });
    },
    [owner]
  );

  const {
    // TODO
    isError, // eslint-disable-line
    isPending, // eslint-disable-line
    reset, // eslint-disable-line
    mutate: onSave,
  } = useMutation({
    mutationFn: async () => dataAssociates.saveBeneficialOwner(owner),
    onMutate: async () => {
      await queryClient.cancelQueries({
        queryKey,
      });

      const previousContract = queryClient.getQueryData<Contract>(queryKey);
      if (!previousContract) {
        return;
      }

      const beneficialOwners: BeneficialOwner[] =
        previousContract.beneficialOwners.map((ownerItem) => {
          if (ownerItem.id === owner.id) {
            return owner;
          }

          return ownerItem;
        });

      const update: Contract = {
        ...previousContract,
        beneficialOwners,
      };

      queryClient.setQueryData<Contract>(queryKey, update);

      return { previousContract };
    },
    onError: (err, update, context) => {
      if (!context) {
        return;
      }

      const prevSavedOwner = context.previousContract.beneficialOwners.find(
        (ownerItem) => ownerItem.id === owner.id
      );

      if (prevSavedOwner) {
        setOwner(prevSavedOwner);
      }

      queryClient.setQueryData(queryKey, context.previousContract);
    },
  });

  const onRemoveCitizenship = useCallback(
    (value: CountryCode) => {
      setOwner((prev) => ({
        ...prev,
        citizenships: owner.citizenships.filter((item) => item !== value),
      }));

      onNextFrame(() => {
        onSave();
      });
    },
    [owner, onSave]
  );

  return (
    <div className={cx(styles.owner, "full-width")}>
      <Form
        formContainer={formRef}
        name={`owner-form-${owner.id}`}
        onSaveTrigger={() => onSave()}
      >
        <AssociateAccordion person={owner} open={open} setOpen={setOpen}>
          <div className={cx(styles.inputs)}>
            <Select
              className={cx("mt-1")}
              label={t("Country of birth")}
              onChange={onChange}
              name="placeOfBirth"
              value={owner.placeOfBirth || ""}
              options={getCountryOptions(i18n.language as Language)}
              validators={[new RequiredValidator(t("Value is required"))]}
            />

            <Select
              className={cx("mt-1")}
              label={t("Country of residence")}
              onChange={onChange}
              name="countryOfResidence"
              value={owner.countryOfResidence || ""}
              options={getCountryOptions(i18n.language as Language)}
              validators={[new RequiredValidator(t("Value is required"))]}
            />

            <Citizenships
              onChange={onChange}
              onRemoveCitizenship={onRemoveCitizenship}
              person={owner}
            />
            {/* <Select
              className={cx("mt-1", "compact")}
              label={t("Add citizenship")}
              onChange={onChange}
              name="citizenships"
              disabled={owner.citizenships.length > 2}
              value=""
              options={getCountryOptions(
                i18n.language as Language,
                owner.citizenships
              )}
            />

            <div
              className={cx(styles.chips, "mt-1", {
                [styles.hasCitizenships]: owner.citizenships.length,
              })}
            >
              {owner.citizenships.map((citizenship) => {
                return (
                  <button
                    onClick={() => {
                      onRemoveCitizenship(citizenship);
                    }}
                    key={citizenship}
                  >
                    <CountryChip countryCode={citizenship} />
                  </button>
                );
              })}
            </div> */}

            <HiddenInput
              value={!!owner.citizenships.length ? true : undefined}
              validators={[new RequiredValidator(t("Required value"))]}
            />

            <Select
              className={cx("mt-2")}
              label={t("Select nationality")}
              onChange={(value: string, name: string) => {
                const isKnownNation = applicableNationalities.includes(
                  value as Country
                );

                let resetNationalId = false;
                // We need to remove previous value
                if (
                  isKnownNation &&
                  latestShownIdInput.current === "dateOfBirth"
                ) {
                  resetNationalId = true;
                }

                if (
                  !isKnownNation &&
                  latestShownIdInput.current === "socialSecurityNumber"
                ) {
                  resetNationalId = true;
                }

                if (isKnownNation) {
                  latestShownIdInput.current = "socialSecurityNumber";
                } else {
                  latestShownIdInput.current = "dateOfBirth";
                }

                setOwner((prev) => ({
                  ...prev,
                  [name]: value,
                  nationalId: resetNationalId ? undefined : owner.nationalId,
                }));
              }}
              disabled={isSweden(contract)}
              name="nationality"
              value={owner.nationality}
              options={getCountryOptions(i18n.language as Language)}
            />

            <Dynamic name={owner.nationality || ""}>
              <IdInput onChange={onChange} owner={owner} />
            </Dynamic>

            {/* <Radiobuttons
              label={t("Ownership is represented...")}
              onChange={(value) => {
                setUseCapitalStake(value);
              }}
              value={useCapitalStake}
              options={options}
            />

            <NumberInput
              label={t(
                useCapitalStake
                  ? "Capital stake (%)"
                  : "Voting rights stake (%)"
              )}
              name={useCapitalStake ? "capitalStake" : "votingRightsStake"}
              onChange={(value) => {
                setOwners((prev) => ({
                  ...prev,
                  [owner.id]: {
                    ...owner,
                    [useCapitalStake ? "capitalStake" : "votingRightsStake"]:
                      value,
                  },
                }));
              }}
              value={
                useCapitalStake ? owner.capitalStake : owner.votingRightsStake
              }
              placeholder={`${t("E.g. 50%")}`}
              validators={[
                new RequiredValidator(t("Value is required")),
                new MinValidator(0, t("Can't be less than {{amount}}%", { amount: 0 })),
                new MaxValidator(100, t("Can't exceed {{amount}}%", { amount: 100 })),
              ]}
            /> */}
          </div>
        </AssociateAccordion>
      </Form>
    </div>
  );
};

const IdInput: React.FunctionComponent<{
  owner: BeneficialOwner;
  onChange: (value: string, name: string) => void;
}> = ({ owner, onChange }) => {
  const contract = useContract();
  const { t } = useTranslation();
  const max = useMemo(() => getMaxDateOfBirth(), []);
  const min = useMemo(() => getMinDateOfBirth(), []);

  const ssnValidationsByCountry = useMemo(() => {
    if (!owner.nationality) {
      return null;
    }

    return Validations[owner.nationality as Country];
  }, [owner.nationality]);

  if (!owner.nationality) {
    return null;
  }

  if (applicableNationalities.includes(owner.nationality as Country)) {
    const hint = ssnValidationsByCountry?.hint ?? "";

    return (
      <TextInput
        label={t("Personal identity number")}
        name="nationalId"
        className={cx("mt-2")}
        onChange={onChange}
        value={owner.nationalId}
        hint={ssnValidationsByCountry?.hint}
        disabled={isSweden(contract)}
        placeholder={`${t("E.g.")} "${ssnValidationsByCountry?.placeholder}"`}
        validators={[
          new RequiredValidator(t("Personal identity number is required")),
          new MinLengthValidator(
            hint.length,
            t("At least {{length}} characters are required ({{hint}})", {
              length: hint.length,
              hint: hint,
            })
          ),
          new MaxLengthValidator(
            hint.length,
            t("Not more than {{length}} characters are allowed ({{hint}})", {
              length: hint.length,
              hint: hint,
            })
          ),
        ]}
      />
    );
  }

  return (
    <TextInput
      type="date"
      className={cx("mt-2")}
      label={t("Date of birth")}
      name="nationalId"
      onChange={onChange}
      value={owner.nationalId}
      inputAttributes={{
        max,
        min,
      }}
      validators={[new RequiredValidator(t("Date of birth is required"))]}
    />
  );
};
