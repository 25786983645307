import React from "react";
import cx from "classnames";
import styles from "./Timeline.module.scss";
import { useTranslation } from "react-i18next";
import { Circle } from "./Circle";
import { Validity } from "../../../../data/models/ContractTypes";
import { Tag } from "../../../../components/Chip/Tag";
import { TimelineStatus } from "./Confirmed";
import { Check } from "./Check";
import { useLinkId } from "../../../../hooks/useLinkId";
import { useSuspenseQueries } from "@tanstack/react-query";
import { dataSigning } from "../../../../data/dataSigning";

interface Props {
  status: TimelineStatus;
}

export const Signatories: React.FunctionComponent<Props> = ({ status }) => {
  const { t } = useTranslation();
  const linkId = useLinkId();
  const done = TimelineStatus.DONE === status;
  const [{ data: signingPerson }] = useSuspenseQueries({
    queries: [dataSigning.fetchSigningStatus(linkId)],
  });

  return (
    <div
      className={cx(styles.signatories, {
        [styles.active]: status === TimelineStatus.ACTIVE,
        [styles.done]: done,
      })}
    >
      <div
        className={cx(styles.inner, {
          [styles.solid]: done,
        })}
      >
        <div className={styles.header}>{t("Signatories")}</div>
        {done ? <Check /> : <Circle />}

        {done
          ? t("All signatories have signed the application")
          : t("We are waiting for all signatories to sign the contract")}
        <div className="mt-2">
          {signingPerson.signees.map((signee) => {
            return (
              <div className={styles.signatory} key={signee.name}>
                <div className={styles.flex}>
                  <div className={styles.name}>{signee.name}</div>
                  <Tag
                    className={styles.tag}
                    validity={signee.signed ? Validity.VALID : Validity.PARTIAL}
                  >
                    {signee.signed ? t("Signed") : t("In progress")}
                  </Tag>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
