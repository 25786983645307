import { API } from "./API";
import {
  AccountListResponse,
  BankListResponse,
  BankType,
  CountryCode,
  KarResponse,
  OwnedBy,
  StartLoginResponse,
} from "./models/ContractTypes";
import { BASE_PATH } from "./proxy";

export const dataBank = {
  verifyNorwegianKar: (ownedBy: OwnedBy, bankAccount: string) =>
    API.post<KarResponse>(`${BASE_PATH}/bank/norway`, {
      ownedBy,
      bankAccount,
    }),

  saveIban: (iban: string) =>
    API.post<KarResponse>(`${BASE_PATH}/bank/iban`, {
      iban,
    }),

  queueToSwedishBGC: (bankAccount: string): Promise<any> =>
    API.post(`${BASE_PATH}/bank/sweden`, {
      bankAccount,
    }),

  listRoaringBanks: (countryCode: CountryCode, bankType: BankType) =>
    API.get<BankListResponse>(
      `${BASE_PATH}/bank/roaring/banks?countryCode=${countryCode}&bankType=${bankType}`
    ),

  startRoaringLogin: (
    bank: string,
    countryCode: CountryCode,
    bankType: BankType
  ) =>
    API.post<StartLoginResponse>(`${BASE_PATH}/bank/roaring/start`, {
      bank,
      countryCode,
      bankType,
    }),

  loadRoaringAvailableAccounts: () =>
    API.get<AccountListResponse>(`${BASE_PATH}/bank/roaring/accounts`),

  selectRoaringAccount: (iban: string) =>
    API.post(`${BASE_PATH}/bank/roaring/select`, {
      iban,
    }),
};
