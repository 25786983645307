import { RefObject } from "react";

export function scrollToError(scrollToRef?: RefObject<HTMLElement>) {
  if (!document) {
    return;
  }

  const scrollToElement = scrollToRef?.current;

  if (!document || !scrollToElement) {
    return;
  }

  console.log("scrollToElement", scrollToElement);
  // console.log("typeof", scrollToElement.scrollIntoView());

  setTimeout(() => {
    scrollToElement.scrollIntoView({
      block: "center",
      inline: "center",
      behavior: "smooth",
    });
  }, 100);
}
