import React from "react";

interface Props {
  fill?: string;
}

export const MittIdLogo: React.FunctionComponent<Props> = ({
  fill = "#0060e6",
}) => {
  return (
    <svg
      viewBox="0 0 1280 333.63934"
      focusable="false"
      aria-label="MitID logo"
      version="1.1"
      id="svg4"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs id="defs8" />
      <path
        style={{
          fill,
          stroke: "none",
          strokeWidth: 20.9836,
        }}
        d="m 568.65574,39.868852 v 62.950818 h 50.36065 v 50.36066 h -50.36065 v 100.72131 c 0,18.88525 10.4918,25.18033 27.27869,25.18033 10.4918,0 20.9836,-2.09836 27.27868,-8.39345 v 56.65574 c -8.39344,4.19672 -25.18032,6.29508 -41.96721,6.29508 -46.16393,0 -75.54098,-23.08196 -75.54098,-73.44262 V 153.18033 h -35.67213 v -50.36066 h 35.67213 V 39.868852 Z"
        id="path354"
        clip-path="none"
      />
      <path
        style={{
          fill,
          stroke: "none",
          strokeWidth: 20.9836,
        }}
        d="m 1089.0492,4.1967213 c 134.2951,0 190.9508,73.4426227 190.9508,163.6721287 0,90.22951 -56.6557,163.67213 -190.9508,163.67213 h -54.5574 V 4.1967213 Z"
        id="path352"
        clip-path="none"
      />
      <path
        id="path350"
        clip-path="none"
        style={{
          fill,
          stroke: "none",
          strokeWidth: 20.9836,
        }}
        d="M 847.73828 0 C 803.67276 0 768 35.672751 768 79.738281 C 768 123.80381 803.67276 159.47461 847.73828 159.47461 C 891.80382 159.47461 927.47461 123.80381 927.47461 79.738281 C 927.47461 35.672751 891.80382 0 847.73828 0 z M 847.73828 188.85156 C 774.29573 188.85156 713.44226 230.81981 705.04883 331.54102 L 992.52539 331.54102 C 984.13196 230.81981 923.2792 188.85156 847.73828 188.85156 z "
      />
      <path
        style={{
          fill,
          stroke: "none",
          strokeWidth: 20.9836,
        }}
        d="M 60.852459,16.786885 157.37705,165.77049 251.80328,16.786885 h 60.85246 V 331.54098 H 247.60656 V 128 l -89.18033,134.29508 h -2.09836 L 65.04918,128 V 331.54098 H 0 V 16.786885 Z"
        id="path348"
        clip-path="none"
      />
      <path
        id="path346"
        clip-path="none"
        style={{
          fill,
          stroke: "none",
          strokeWidth: 20.9836,
        }}
        d="M 402.88477 0 C 381.90118 4.7369468e-15 365.11523 16.785945 365.11523 37.769531 C 365.11523 58.753116 381.90118 75.541016 402.88477 75.541016 C 423.86834 75.541016 440.65625 58.753116 440.65625 37.769531 C 440.65625 16.785945 423.86834 0 402.88477 0 z M 371.41016 102.82031 L 371.41016 331.54102 L 434.36133 331.54102 L 434.36133 102.82031 L 371.41016 102.82031 z "
      />
    </svg>
  );
};
