import { FaPiggyBank } from "react-icons/fa6";
import { useRiskCase } from "../../hooks/useRiskCase";
import { useTranslation } from "react-i18next";
import { BackofficeCard } from "../../components/card/BackofficeCard";
import { BackofficeCardHeader } from "../../components/card/BackofficeCardHeader";
import { DataList } from "../../components/Datalist/DataList";
import { DataListItem } from "../../components/Datalist/DataListItem";
import { Suspense } from "../../../Suspense";
import Skeleton from "react-loading-skeleton";

export const RiskBankAccountCard: React.FC = () => {
  const { t } = useTranslation();

  return (
    <BackofficeCard>
      <BackofficeCardHeader
        title={t("Bank account")}
        icon={FaPiggyBank}
        verified="Open Banking / BG"
      />
      <Suspense skeleton={<Skeleton height={100} className="mb-2" />}>
        <Inner />
      </Suspense>
    </BackofficeCard>
  );
};

const Inner = () => {
  const { t } = useTranslation();

  const {
    data: { contract },
  } = useRiskCase();

  const { bank: data } = contract;

  const { bank, iban, accountHolder } = data || {};

  return (
    <div>
      <DataList type="horizontal" align="left">
        <DataListItem label={t("IBAN or Account number")} value={iban} />
        <DataListItem label={t("Bank")} value={bank} />
        <DataListItem label={t("Account holder")} value={accountHolder} />
        <DataListItem label={t("Country")} value={contract.country} />
      </DataList>
    </div>
  );
};
