import React, { useState, useCallback, useContext } from "react";
import cx from "classnames";
import styles from "./FakeLogin.module.scss";
import { useTranslation } from "react-i18next";
import { TextInput } from "../../../modules/Forms/TextInput";
import { Country } from "../../../data/models/ContractTypes";
import { Button } from "../../../components/Buttons/Button";
import {
  ButtonContent,
  CountryToMethod,
  InitLoginRequest,
} from "./Identification";
import { QueryKey } from "../../../data/Store";
import { GlobalContext, GlobalProps, isProd, queryClient } from "../../..";
import { Status } from "../../../modules/Forms/FormContext";
import { useMutation } from "@tanstack/react-query";
import { dataStartOnboarding } from "../../../data/dataStartOnboarding";
import { dataSession } from "../../../data/dataSession";
import { Or } from "../../../components/Or/Or";
import { Form } from "../../../modules/Forms/Form";
import { MinLengthValidator } from "../../../modules/Forms/validators/MinLengthValidator";
import { MaxLengthValidator } from "../../../modules/Forms/validators/MaxLengthValidator";
import { RequiredValidator } from "../../../modules/Forms/validators/RequiredValidator";

interface Props {
  resetBoundary: () => void;
  resetQuery: () => void;
}

interface Fake extends InitLoginRequest {
  name: string;
  nationalId: string;
}

export const FakeLogin: React.FunctionComponent<Props> = ({
  resetBoundary,
  resetQuery,
}) => {
  const { i18n } = useTranslation();
  const { search } = useContext<GlobalProps>(GlobalContext);
  const [fake, setFake] = useState<Fake>({
    name: "",
    country: Country.SWEDEN,
    nationalId: "",
    language: i18n.language as any, // ?
    MOVE5000: 1,
    DX8000: 2,
    returnUrl: "",
    ...search,
  });

  const {
    // TODO
    mutate: onLogin,
    isPending: loginPending,
    isError, // eslint-disable-line
  } = useMutation({
    mutationFn: () => dataSession.fakeLogin(fake),
    onSuccess: () => {
      resetBoundary();
      resetQuery();
      queryClient.invalidateQueries({
        queryKey: dataStartOnboarding.getCompanySelectionKey(),
      });
    },
  });

  const onChange = useCallback((value: string, name: string) => {
    setFake((prev) => ({
      ...prev,
      [name]: value,
    }));
  }, []);

  const signingMethod =
    CountryToMethod[search[QueryKey.COUNTRY] || Country.SWEDEN];

  if (isProd()) {
    return null;
  }

  return (
    <div className={cx("mb-6", styles.wrapper)}>
      <div className={cx(styles.debug, "mb-8")}>
        <h3>Fake login</h3>
        <p>
          User won't have to add neither <b>name</b> nor <b>ssn</b>. It's just
          to fake our identity in dev environment.
        </p>
        <Form
          name="fake-login"
          onSubmit={(_, form) => {
            if (form.isInvalid) {
              return;
            }

            onLogin();
          }}
        >
          <TextInput
            className="mt-4"
            name="name"
            label="Set name"
            onChange={onChange}
            value={fake.name}
            validators={[new RequiredValidator("Name is required")]}
          />

          <TextInput
            name="nationalId"
            label="Personal identity number"
            onChange={onChange}
            value={fake.nationalId}
            validators={[
              new MinLengthValidator(12, "Needs to be 12 characters"),
              new MaxLengthValidator(12, "Needs to be 12 characters"),
            ]}
          />
          <Button
            block
            type="submit"
            status={loginPending ? Status.DISABLED : Status.DEFAULT}
          >
            <ButtonContent signingMethod={signingMethod} />
          </Button>
        </Form>

        <div className="mt-2" />
      </div>

      <Or className="mb-6" />
    </div>
  );
};
