import React, { useState } from "react";
import cx from "classnames";
import styles from "./Financial.module.scss";
import { OnboardingPath } from "../routes";
import { Wrapper } from "../../../components/Wrapper";
import { useTranslation } from "react-i18next";
import { Form } from "../../../modules/Forms/Form";
import { TextArea } from "../../../modules/Forms/TextArea";
import { Contract, FinancialKyc } from "../../../data/models/ContractTypes";
import { useContract } from "../../../hooks/useContract";
import { useStoryNavigate } from "../../../hooks/useStoryNavigate";
import { StoryButtons } from "../../StoryButtons";
import { View } from "../../../modules/View/View";
import { MaxLengthValidator } from "../../../modules/Forms/validators/MaxLengthValidator";
import { RequiredValidator } from "../../../modules/Forms/validators/RequiredValidator";
import { useMutation } from "@tanstack/react-query";
import { dataContract } from "../../../data/dataContract";
import { queryClient } from "../../..";
import { dataKyc } from "../../../data/dataKyc";

const MAX_LENGTH_DESCRIPTION = 2000;

export const Financial: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { navigate } = useStoryNavigate();
  const contract = useContract();

  const [financial, setFinancial] = useState<FinancialKyc>({
    ...contract.financialKyc,
  });

  const queryKey = dataContract.getContractKey();

  const {
    // TODO
    isError, // eslint-disable-line
    isPending, // eslint-disable-line
    reset, // eslint-disable-line
    mutate: onSave,
  } = useMutation({
    mutationFn: async () => dataKyc.saveFinancialKyc(financial),
    onMutate: async () => {
      await queryClient.cancelQueries({
        queryKey,
      });

      const previousContract = queryClient.getQueryData<Contract>(queryKey);
      if (!previousContract) {
        return;
      }

      const update: Contract = {
        ...previousContract,
        financialKyc: financial,
      };

      queryClient.setQueryData<Contract>(queryKey, update);

      return { previousContract };
    },
    onError: (err, store, context) => {
      if (!context) {
        return;
      }

      setFinancial(context.previousContract.financialKyc);
      queryClient.setQueryData(queryKey, context.previousContract);
    },
  });

  return (
    <Wrapper>
      <View header={t("Tell us more of your business")}>
        <p>
          {t(
            "Due to anti money laundering regulations, we're required to know more about your business model, what you sell and what customers you serve, please answer the questions below."
          )}
        </p>

        <Form
          onSaveTrigger={() => {
            onSave();
          }}
          onSubmit={(event, form) => {
            if (!form.isValid) {
              return;
            }

            navigate(OnboardingPath.FINANCIAL_ESTIMATES);
          }}
        >
          <div className="mt-6">
            <TextArea
              label={t(
                "Tell us more about the nature of your business, your business model and customers your serve"
              )}
              name="businessModel"
              value={financial.businessModel}
              className={cx(styles.model, styles.area)}
              onChange={(value, name) => {
                setFinancial((prev) => ({
                  ...prev,
                  [name]: value,
                }));
              }}
              placeholder={t(
                "E.g. We are a construction company that provides repair services and sells / leases repair equipment to customers and businesses in our local area."
              )}
              validators={[
                new RequiredValidator(t("Value is required")),
                new MaxLengthValidator(
                  MAX_LENGTH_DESCRIPTION,
                  t(
                    `Business description must not exceed ${MAX_LENGTH_DESCRIPTION} characters`
                  )
                ),
              ]}
            />
          </div>
          <div className="mt-6">
            <TextArea
              label={t(
                "Which products or services will you accept card payments for?"
              )}
              name="productDescription"
              value={financial.productDescription}
              className={cx(styles.model, styles.area)}
              onChange={(value, name) => {
                setFinancial((prev) => ({
                  ...prev,
                  [name]: value,
                }));
              }}
              placeholder={t(
                "E.g. Building materials, power tools or protective gear. We also take payments for services we offer, such as repair, lease of equipment and consultancy."
              )}
              validators={[
                new RequiredValidator(t("Value is required")),
                new MaxLengthValidator(
                  MAX_LENGTH_DESCRIPTION,
                  t(
                    `Product description must not exceed ${MAX_LENGTH_DESCRIPTION} characters`
                  )
                ),
              ]}
            />
          </div>

          <div className="mt-6">
            <StoryButtons />
          </div>
        </Form>
      </View>
    </Wrapper>
  );
};
