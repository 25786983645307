import React, { useCallback, useMemo, useState } from "react";
import { Wrapper } from "../../../components/Wrapper";
import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import { dataContract } from "../../../data/dataContract";
import {
  Contract,
  Country,
  Currency,
  FinancialKyc,
} from "../../../data/models/ContractTypes";
import { TFunction } from "i18next";
import { Form } from "../../../modules/Forms/Form";
import { useStoryNavigate } from "../../../hooks/useStoryNavigate";
import { OnboardingPath } from "../routes";
import { Buttons } from "./Buttons";
import { StoryButtons } from "../../StoryButtons";
import { View } from "../../../modules/View/View";
import { useContract, useInvalidateContract } from "../../../hooks/useContract";
import { queryClient } from "../../..";
import { dataKyc } from "../../../data/dataKyc";
import styles from "./FinancialEstimates.module.scss";

enum Label {
  TURNOVER = "TURNOVER",
  TRANSACTIONS = "TRANSACTIONS",
  CARDS = "CARDS",
  MINIMUM = "MINIMUM",
  MAXIMUM = "MAXIMUM",
}

export interface FinancialValue {
  text: string;
  value: number;
}

function getLabels(
  t: TFunction,
  country: Country
): Record<Label, FinancialValue[]> {
  switch (country) {
    case Country.SWEDEN:
      return {
        [Label.TURNOVER]: [
          {
            text: t("0 - 500 000"),
            value: 50_000,
          },
          {
            text: t("500 001 - 1 000 000"),
            value: 1_000_000,
          },
          {
            text: t("1 000 001 - 2 000 001"),
            value: 1_500_000, // TODO, okay?
          },
          {
            text: t("More than 1 mkr"),
            value: 5_000_000, // TODO, okay?
          },
        ],
        [Label.TRANSACTIONS]: [
          {
            text: t("0 - 500"),
            value: 500,
          },
          {
            text: t("501 - 10 000"),
            value: 10_000,
          },
          {
            text: t("More than 10 000"),
            value: 50_000, // TODO, okay?
          },
        ],
        [Label.CARDS]: [
          {
            text: t("0 - 500"),
            value: 500,
          },
          {
            text: t("501 - 10 000"),
            value: 10_000,
          },
          {
            text: t("More than 10 000"),
            value: 50_000, // TODO, okay?
          },
        ],
        [Label.MINIMUM]: [
          {
            text: t("0 - 500"),
            value: 500,
          },
          {
            text: t("501 - 10 000"),
            value: 10_000,
          },
          {
            text: t("More than 10 000"),
            value: 50_000, // TODO, okay?
          },
        ],
        [Label.MAXIMUM]: [
          {
            text: t("0 - 500"),
            value: 500,
          },
          {
            text: t("501 - 10 000"),
            value: 10_000,
          },
          {
            text: t("More than 10 000"),
            value: 50_000, // TODO, okay?
          },
        ],
      };

    default:
      return {
        [Label.TURNOVER]: [
          {
            text: t("0 - 50 000"),
            value: 50_000,
          },
          {
            text: t("50 001 - 100 000"),
            value: 100_000,
          },
          {
            text: t("More than 100 000"),
            value: 500_000, // TODO, okay?
          },
        ],
        [Label.TRANSACTIONS]: [
          {
            text: t("0 - 500"),
            value: 500,
          },
          {
            text: t("501 - 10 000"),
            value: 10_000,
          },
          {
            text: t("More than 10 000"),
            value: 50_000, // TODO, okay?
          },
        ],
        [Label.CARDS]: [
          {
            text: t("0 - 500"),
            value: 500,
          },
          {
            text: t("501 - 10 000"),
            value: 10_000,
          },
          {
            text: t("More than 10 000"),
            value: 50_000, // TODO, okay?
          },
        ],
        [Label.MINIMUM]: [
          {
            text: t("0 - 50"),
            value: 50,
          },
          {
            text: t("51 - 1 000"),
            value: 1_000,
          },
          {
            text: t("More than 1 000"),
            value: 5_000, // TODO, okay?
          },
        ],
        [Label.MAXIMUM]: [
          {
            text: t("0 - 50"),
            value: 50,
          },
          {
            text: t("51 - 1 000"),
            value: 1_000,
          },
          {
            text: t("More than 1 000"),
            value: 5_000, // TODO, okay?
          },
        ],
      };
  }
}

export const FinancialEstimates: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { navigate } = useStoryNavigate();
  const contract = useContract();
  const queryKey = dataContract.getContractKey();
  const invalidate = useInvalidateContract();

  const labels = useMemo(() => {
    return getLabels(t, contract.country);
  }, [t, contract.country]);

  const [financial, setFinancial] = useState<FinancialKyc>(
    contract.financialKyc
  );

  const {
    // TODO
    mutate: commit,
    isError: isCommitError, // eslint-disable-line
  } = useMutation({
    mutationFn: () => dataKyc.commitFinancialKyc(),
    onSuccess: () => {
      invalidate();
      navigate(OnboardingPath.BUSINESS_RISK);
    },
  });

  const {
    // TODO
    isError, // eslint-disable-line
    isPending, // eslint-disable-line
    reset, // eslint-disable-line
    mutate: onSave,
  } = useMutation({
    mutationFn: async (copy: FinancialKyc) => dataKyc.saveFinancialKyc(copy),
    onMutate: async () => {
      await queryClient.cancelQueries({
        queryKey,
      });

      const previousContract = queryClient.getQueryData<Contract>(queryKey);
      if (!previousContract) {
        return;
      }

      const update: Contract = {
        ...previousContract,
        financialKyc: financial,
      };

      queryClient.setQueryData<Contract>(queryKey, update);

      return { previousContract };
    },
    onError: (err, financialKyc, context) => {
      if (!context) {
        return;
      }

      setFinancial(context.previousContract.financialKyc);
      queryClient.setQueryData(queryKey, context.previousContract);
    },
  });

  const onChange = useCallback(
    (name: string, value: number) => {
      const copy = {
        ...financial,
        [name]: value,
      };
      setFinancial(copy);
      onSave(copy);
    },
    [onSave, financial]
  );

  return (
    <Wrapper>
      <Form
        onSaveTrigger={() => {
          onSave(financial);
        }}
        onSubmit={(event, form) => {
          if (!form.isValid) {
            return;
          }

          commit();
        }}
      >
        <View header={t("Help us understand your business")}>
          <p>
            {t(
              "Due to anti money laundering regulations, we're required to know more about your business model, what you sell and what customers you serve, please answer the questions below."
            )}
          </p>

          <Buttons
            values={labels[Label.TURNOVER]}
            name="estimatedAnnualTurnover"
            financial={financial}
            onChange={onChange}
          >
            <h4 className={styles.header}>
              {t("Estimated annual turnover in {{currency}}", {
                currency: Currency[contract.country],
              })}
            </h4>
            <i>
              {t(
                "Give us an indication of your annual turnover for the first year"
              )}
            </i>
          </Buttons>

          <Buttons
            values={labels[Label.TRANSACTIONS]}
            name="estimatedTransactionsPerYear"
            financial={financial}
            onChange={onChange}
          >
            <h4 className={styles.header}>
              {t("Estimated annual transactions")}
            </h4>

            <i>
              {t(
                "Give us an indication of the total number of transactions you expect per year, including all payment methods"
              )}
            </i>
          </Buttons>

          <Buttons
            values={labels[Label.MINIMUM]}
            name="estimatedRangeOfTransactionValueMin"
            financial={financial}
            onChange={onChange}
          >
            <h4 className={styles.header}>
              {t("Most common minimum transaction amount")}
            </h4>
            <i>{t("What is the most common minimum transaction amount?")}</i>
          </Buttons>

          <Buttons
            values={labels[Label.MAXIMUM]}
            name="estimatedRangeOfTransactionValueMax"
            financial={financial}
            onChange={onChange}
          >
            <h4 className={styles.header}>
              {t("Most common maximum transaction amount")}
            </h4>

            <i>{t("What is the most common maximum transaction amount?")}</i>
          </Buttons>

          <div className="mt-6">
            <StoryButtons />
          </div>
        </View>
      </Form>
    </Wrapper>
  );
};
