import { FaRectangleList } from "react-icons/fa6";
import { useRiskCase } from "../../hooks/useRiskCase";
import { useTranslation } from "react-i18next";
import { BackofficeCard } from "../../components/card/BackofficeCard";
import { BackofficeCardHeader } from "../../components/card/BackofficeCardHeader";
import { DataList } from "../../components/Datalist/DataList";
import { DataListItem } from "../../components/Datalist/DataListItem";
import { getIntlDateTime } from "../../../../components/dateUtils";
import { Suspense } from "../../../Suspense";
import Skeleton from "react-loading-skeleton";
import { Button } from "../../../../components/Buttons/Button";
import { useRiskCaseTab } from "../../hooks/useRiskCaseTab";

export const RiskCaseOverviewCard: React.FC = () => {
  const { t } = useTranslation();

  return (
    <BackofficeCard>
      <BackofficeCardHeader
        title={t("Case information")}
        icon={FaRectangleList}
      />
      <Suspense skeleton={<Skeleton height={100} className="mb-2" />}>
        <Inner />
      </Suspense>
    </BackofficeCard>
  );
};

const Inner = () => {
  const { t } = useTranslation();
  const [, setRiskTab] = useRiskCaseTab();

  const {
    data: { caseDetails, contract },
  } = useRiskCase();

  return (
    <div>
      <DataList type="horizontal" align="left">
        <DataListItem
          label={t("Application started")}
          value={getIntlDateTime(contract.started)}
        />
        <DataListItem
          label={t("Onboarding process started")}
          value={getIntlDateTime(caseDetails.created)}
        />
        {caseDetails.claimedBy && (
          <DataListItem
            label={t("Claimed by")}
            value={
              caseDetails.claimedBy ? caseDetails.claimedBy : t("Unclaimed")
            }
          />
        )}
      </DataList>

      <hr />

      <DataList type="horizontal" align="left">
        <DataListItem
          label={t("Is existing customer")}
          value={
            contract.existingCustomer
              ? t("Yes")
              : contract.existingCustomer === false
              ? t("No")
              : t("Unknown")
          }
        />
      </DataList>

      {!!caseDetails.closed && (
        <>
          <hr />
          <DataList type="horizontal" align="left">
            <DataListItem
              label={t("Closed")}
              value={getIntlDateTime(caseDetails.closed)}
            />
            <DataListItem label={t("Closed by")} value={caseDetails.closedBy} />
            <DataListItem
              label={t("Due diligence level")}
              value={caseDetails.dueDiligence}
            />
            <DataListItem
              label={t("Delayed settlement")}
              value={caseDetails.delayedSettlement + " days"}
            />
            <DataListItem
              label={t("Rolling reserve")}
              value={caseDetails.rollingReserve + "%"}
            />
          </DataList>
          <Button
            size="mini"
            variant="outlined"
            className="mt-2"
            onClick={() => setRiskTab("rationale")}
            block
          >
            {t("View final comment")}
          </Button>
        </>
      )}
    </div>
  );
};
