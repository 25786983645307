import {
  AnimatePresence,
  AnimationControls,
  motion,
  useAnimation,
  Variants,
} from "framer-motion";
import { Children, useEffect, useRef, useState } from "react";
import styles from "./TabView.module.scss";

const variants: Variants = {
  enter: (_direction: number) => ({
    // x: direction > 0 ? "100%" : "-100%",
    opacity: 0,
  }),
  active: {
    x: 0,
    opacity: 1,
  },
  exit: (_direction: number) => ({
    // x: direction > 0 ? "-100%" : "100%",
    opacity: 0,
    // position: "absolute",
  }),
};

interface Props extends React.PropsWithChildren {
  index: number;
}

export const TabView: React.FunctionComponent<Props> = ({
  index,
  children,
}) => {
  const lastIndex = useRef(index);
  const [direction, setDirection] = useState(0);
  const [internalIndex, setInternalIndex] = useState(index);
  // const [controls, ref] = useAutoHeightAnimation([internalIndex]);

  // need an internal index to be able to animate the direction properly
  useEffect(() => {
    if (lastIndex.current < index) {
      setDirection(1);
    } else {
      setDirection(-1);
    }
    setInternalIndex(index);
    lastIndex.current = index;

    // Scroll to top on tab change
    window.scrollTo(0, 0);
  }, [index]);

  // clamp the index to ensure it doesn't go out of children bounds
  const clampedIndex = Math.min(
    Math.max(0, internalIndex),
    Children.count(children) - 1
  );

  return (
    <div className={styles.container}>
      <AnimatePresence custom={direction} initial={false}>
        <motion.div
          key={clampedIndex}
          className={styles.view}
          variants={variants}
          initial="enter"
          animate="active"
          // exit="exit"
          transition={{
            type: "spring",
            stiffness: 150,
            damping: 20,
          }}
          drag={false} // set to x to enable drag in the future, need to implement drag release listeners as well
          dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
          custom={direction}
        >
          {Children.toArray(children)[clampedIndex]}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

const _useAutoHeightAnimation = (
  deps: any[]
): [AnimationControls, React.RefObject<HTMLDivElement>] => {
  const controls = useAnimation();
  const ref = useRef<HTMLDivElement>(null);
  const height = useRef<number | null>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.style.height = "auto";
      const newHeight = ref.current.offsetHeight;

      if (height.current !== null) {
        controls.set({ height: height.current });
        controls.start({ height: newHeight, transition: { duration: 0.3 } });
      }

      height.current = newHeight;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, controls, ...deps]);

  return [controls, ref];
};
