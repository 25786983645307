import { ReactNode } from "react";
import { OnboardingPath } from "../stories/Onboarding/routes";
import { SigningPath } from "../stories/Signing/routes";
import { SupplementPath } from "../stories/Supplement/routes";

export type Path = SigningPath | OnboardingPath | SupplementPath;

export interface StoryFrameProps {
  path: Path;
  element: ReactNode;
  hideProgress?: boolean;
}

export interface NavigationRoutes {
  routes: StoryFrameProps[];
  baseRoute: string;
}

export enum Direction {
  FORWARDS,
  BACKWARDS,
}
