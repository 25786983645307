import React, { useState, useCallback } from "react";
import cx from "classnames";
import styles from "./Iban.module.scss";
import { useTranslation } from "react-i18next";
import { Wrapper } from "../../../../components/Wrapper";
import { Form } from "../../../../modules/Forms/Form";
import { View } from "../../../../modules/View/View";
import { useContract } from "../../../../hooks/useContract";
import { useStoryNavigate } from "../../../../hooks/useStoryNavigate";
import {
  LegalEntityType,
  Validity,
} from "../../../../data/models/ContractTypes";
import { SavedAccount } from "../SavedAccount";
import { useMutation } from "@tanstack/react-query";
import { dataBank } from "../../../../data/dataBank";
import { OnboardingPath } from "../../routes";
import { TextInput } from "../../../../modules/Forms/TextInput";
import { RequiredValidator } from "../../../../modules/Forms/validators/RequiredValidator";
import { MinLengthValidator } from "../../../../modules/Forms/validators/MinLengthValidator";
import { MaxLengthValidator } from "../../../../modules/Forms/validators/MaxLengthValidator";
import { Dynamic } from "../../../../components/Animations/Dynamic";
import { GenericError } from "../../../../components/Errors/GenericError";
import { StoryButtons } from "../../../StoryButtons";

const MIN_LENGTH = 16; // TODO
const MAX_LENGTH = 30; // TODO

export const Iban: React.FunctionComponent = () => {
  const contract = useContract();
  const { t } = useTranslation();
  const [iban, setIban] = useState<string>("");

  // const [info, setInfo] = useState<Kontonummer | null>(null);
  const [hasShowedWarning, setHasShowedWarning] = useState<boolean>(false);
  // const queryClient = useQueryClient();
  const { navigate } = useStoryNavigate();

  const {
    mutate: onSave,
    isPending,
    isError,
    reset,
  } = useMutation({
    mutationFn: () => {
      return dataBank.saveIban(iban);
    },
    onSuccess: () => {
      if (
        contract.companyDetails.legalEntityType ===
        LegalEntityType.SOLE_PROPRIETARY
      ) {
        navigate(OnboardingPath.SUMMARY);
      } else {
        navigate(OnboardingPath.OWNERS);
      }
    },
  });

  const next = useCallback(() => {
    if (
      contract.companyDetails.legalEntityType ===
      LegalEntityType.SOLE_PROPRIETARY
    ) {
      navigate(OnboardingPath.SUMMARY);
    } else {
      navigate(OnboardingPath.OWNERS);
    }
  }, [contract, navigate]);

  return (
    <Wrapper>
      <Form
        name="bank-account"
        onSubmit={(_, form) => {
          if (!form.isValid) {
            return;
          }

          if (!hasShowedWarning) {
            setHasShowedWarning(true);
            return;
          }

          setHasShowedWarning(false);
          onSave();
        }}
      >
        <View
          header={t("Payout account")}
          size="small"
          fixedValidity={
            contract.bankAccount?.iban ? Validity.VALID : undefined
          }
        >
          <p>
            {t(
              "Enter your bank account where you wish to recieve your payouts, the bank account must be owned by the company."
            )}
          </p>

          <SavedAccount bankAccount={contract.bankAccount} next={next} />

          <div className="mt-6">
            <b>{t("Example")}:</b> DK5000400440116243{" "}
            {/** TODO should be country specific*/}
            <TextInput
              className={cx("mt-4")}
              name="iban"
              label="IBAN"
              value={iban}
              disabled={isPending}
              validators={[
                new RequiredValidator(t("Required")),
                new MinLengthValidator(
                  MIN_LENGTH,
                  t("At least {{min}} digits are required", {
                    min: MIN_LENGTH,
                  })
                ),
                new MaxLengthValidator(
                  MAX_LENGTH,
                  t("Not more than {{max}} digits are allowed", {
                    max: MAX_LENGTH,
                  })
                ),
              ]}
              onChange={(value) => {
                reset();
                setIban(value);
                setHasShowedWarning(false);
              }}
            />
            <Dynamic name={hasShowedWarning ? "warning" : ""}>
              {hasShowedWarning ? (
                <div className={styles.warning}>
                  {t(
                    "Iban numbers are sometimes hard to verify. In this case, we are unable to verify the entered account number. Please check the account number again. If it looks good, you may proceed."
                  )}
                </div>
              ) : null}
            </Dynamic>
            <Dynamic name={isError ? "error" : ""}>
              {isError ? (
                <div className={styles.error}>
                  <GenericError />
                </div>
              ) : null}
            </Dynamic>
            <div className="mt-6">
              <StoryButtons disabled={isPending} />
            </div>
          </div>
        </View>
      </Form>
    </Wrapper>
  );
};
